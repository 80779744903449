import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';

import Adminheader from './Adminheader';
import Adminsidebar from './Adminsidebar';
import Editsettingmodal from "./Editsettingmodal";
import Addsettingmodal from "./Addsettingmodal";


import Web3 from "web3";
import '../../css/styles.css';

import coin from "../../images/coin.png"
import favicon from "../../images/Bitdeal/logo_curve.png"

import loader from "../../images/loader.gif"
import { Dropdown, Nav, Tab, Row, Col, Container, ProgressBar, InputGroup,Form, FormControl } from 'react-bootstrap';
import { getsettinghook } from '../../hooks/usebackend';

const cookies = new Cookies();
class Settings extends Component {
   
  showLoader()
  {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.5;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");


  }

  hideLoader()
  {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");



  }
  
    componentDidMount()
    {
      // this.showLoader();
     this.hideLoader();

  
    }

 


   
    constructor(props) {
      super(props);
      this.state = {
        passwordeye: false
      }
     
    }
    




    render() {
     

      const location = this.props.location.pathname.split('/')[1];

      
	return (
    <div id="loader_main">
    <div id="loader_div">
    <span className="spin_round">

    </span>
    <img src={favicon} className="logo_load" />
  </div>
        <div className='logo_overlay' id="logo_overlay">
      

           <Adminheader />
        
           <div className="whole_sec pb-5">
            <div className='flex_side_right'>
            <Adminsidebar />
           {/* ongoing_sec */}
           <div className='right_side_sec'>
        
              <div className="right_side_spacing">
               <div className='ongoing_sec proj_bg pb-5'>
            <div className="container px-1">
                <div className='top_heqad_sec mt-5'>
                <p className="banner_subtitle form_subhead">Admin Settings</p>

                   
                  <div className="row">

                      <div className="col-12 col-md-10 col-lg-9 mt-md-5 mb-md-4 mx-auto">

<div className='card_bg card card_admin_gorm'>

                        <div className='col-12 col-md-12 mt-5 mb-0'>
        
        <p className='input_desc_sm'>Email Address</p>
        <div className="inputs input-groups">
        <InputGroup className="">
            <FormControl id="email" placeholder="Enter your email"
                aria-describedby="basic-addon2"
               
            />
          
        </InputGroup>
        <span className="text-danger f-12 d-block text-left">
          {/* {this?.state?.errors?.email} */}
          </span>
    </div>   
            </div>


            <div className='col-12 col-md-12 mt-3 mb-0'>
        
        <p className='input_desc_sm'>Password</p>
        <div className="inputs input-groups pasw_frp_inner">
        <InputGroup className="">
        <InputGroup.Text className="left_icon"><span className='fa fa-key'></span></InputGroup.Text>
            <FormControl id="password" placeholder="Enter your password" type = {this.state.passwordeye?"password":"text"}
            className="center_icon"
                aria-describedby="basic-addon2"
              
            />
          {!this.state.passwordeye ? 
        <InputGroup.Text className="right_icon"><span className='fa fa-eye-slash' onClick={()=>this.setState({passwordeye:!this.state.passwordeye})}></span></InputGroup.Text>
        :<InputGroup.Text className="right_icon"><span className='fa fa-eye' onClick={()=>this.setState({passwordeye:!this.state.passwordeye})}></span></InputGroup.Text>
        }
        </InputGroup>
        <span className="text-danger f-12 d-block text-left">
          {/* {this?.state?.errors?.password} */}
          </span>
    </div>
    
            </div>

            <div className="col-12 col-md-12 mt-md-5 mb-md-4">
                        <div className='text-right mt-3 mt-md-0 mb-4 mb-md-0'>
            <button className="get-started-btn btn_width_auto"
             >Submit</button>
            </div>

            </div>
                        </div>
                       
                        {/* <hr className='hr_yellow mt-5'/> */}
                      </div>
                  
                    
                  </div>
                </div>
            </div>
           
         
            </div>
            {/* end ongoing_sec */}

            </div>
            </div>
            </div>
            </div>


            {/* <Footer /> */}
        </div>
        </div>
        )
    }
}

export default Settings