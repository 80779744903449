import Validator from "validator";
import isEmpty from "is-empty";
import { IsValidAddress } from "./useContract";

export const kycvalid = (data,id,identitytype) => {
    console.log("data", data)
    var errors = {};
    var isValid = true;
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
    var letters =/^[A-Za-z\s]*$/;
    var numbers = /^[0-9]+$/;
    let regex_no = /^[ A-Za-z0-9_@./#&+-]*$/
    let url =/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
    if (Validator?.isEmpty(data) && id=="identitytype") {
        isValid = false;
        errors.identitytype = "Identity Type field is required !";
    }
    if (identitytype == "Individual") {
        if (Validator.isEmpty(data) && id=="emailid") {
            isValid = false;
            errors.emailid = "Email Id field is required !";
        }
        else if(!emailRegex.test(data) && id=="emailid"){
            isValid = false;
            errors.emailid = "Email Id field should be in correct format!";
        }
        if (Validator.isEmpty(data) && id=="city") {
            isValid = false;
            errors.city = "City field is required !";
        }
        else if(!letters.test(data) && id=="city"){
            isValid = false;
            errors.city = "City field should contain only alphabets!";
        }
        if (Validator.isEmpty(data) && id=="region") {
            isValid = false;
            errors.region = "Region field is required !";
        }
        else if(!letters.test(data) && id=="region"){
            isValid = false;
            errors.region = "Region field should contain only alphabets!";
        }
        if (Validator.isEmpty(data) && id=="country") {
            isValid = false;
            errors.country = "Country field is required !";
        }
        else if(!letters.test(data) && id=="country"){
            isValid = false;
            errors.country = "Country field should contain only alphabets!";
        }
        if (Validator.isEmpty(data) && id=="zipcode") {
            isValid = false;
            errors.zipcode = "Zipcode field is required !";
        }
        else if(!numbers.test(data) && id=="zipcode"){
            isValid = false;
            errors.zipcode = "Zipcode field should contain only numbers!";
        }
        if (Validator.isEmpty(data) && id=="contactnumber") {
            isValid = false;
            errors.contactnumber = "Contact number field is required !";
        }
        else if(!numbers.test(data) && id=="contactnumber"){
            isValid = false;
            errors.contactnumber = "Contact number field should contain only numbers !";
        }
        if (Validator?.isEmpty(data) && id=="idtype") {
            isValid = false;
            errors.idtype = "Document Type field is required !"
        }
        if (Validator?.isEmpty(data) && id=="idnumber") {
            isValid = false;
            errors.idnumber = "Id Number field is required !";
        }
        else if(!regex_no.test(data) && id=="idnumber"){
            isValid = false;
            errors.idnumber = "Id number field should be in correct format !";
        }
        if ((!data) && id=="front") {
            isValid = false;
            errors.front = "Zip file is required !";
        }
        else if (!filevalidate(data) && id=="front" ) {
            isValid = false;
            errors.front = "Invalid file format !"
        }

    } else if (identitytype == "Company") {
        if (Validator.isEmpty(data) && id=="businessOwnerName") {
            isValid = false;
            errors.businessOwnerName = "Business Owner Name field is required !";
        }
        else if(!letters.test(data) && id=="businessOwnerName"){
            isValid = false;
            errors.businessOwnerName = "Business Owner Name field should contain only alphabets!";
        }
        if (Validator.isEmpty(data) && id=="surname") {
            isValid = false;
            errors.surname = "Surname field is required !";
        }
        else if(!letters.test(data) && id=="surname"){
            isValid = false;
            errors.surname = "Surname field should contain only alphabets!";
        }
        if (Validator.isEmpty(data) && id=="businessname") {
            isValid = false;
            errors.businessname = "Business name field is required !";
        }
        else if(!letters.test(data) && id=="businessname"){
            isValid = false;
            errors.businessname = "Business name field should contain only alphabets!";
        }
        if (Validator.isEmpty(data) && id=="companynumber") {
            isValid = false;
            errors.companynumber = "Company number field is required !";
        }
        else if(!regex_no.test(data) && id=="companynumber"){
            isValid = false;
            errors.companynumber = "Company number field should be in correct format!";
        }
        if (Validator.isEmpty((data).toString()) && id=="registrationdate") {
            isValid = false;
            errors.registrationdate = "Registration date field is required !";
        }
        if (Validator.isEmpty(data) && id=="description") {
            isValid = false;
            errors.description = "Description field is required !";
        }
        else if(!letters.test(data) && id=="description"){
            isValid = false;
            errors.description = "Description field should contain only alphabets!";
        }
        if (Validator?.isEmpty(data) && id=="websiteurl") {
            isValid = false;
            errors.websiteurl = "Website Url field is required !";
        }
        else if(!url.test(data) && id=="websiteurl"){
            isValid = false;
            errors.websiteurl = "Website Url field should be in correct format!";
        }
         if (Validator.isEmpty(data) && id=="businessemail") {
            isValid = false;
            errors.businessemail = "Business email field is required !";
        }
        else if(!emailRegex.test(data) && id=="businessemail"){
            isValid = false;
            errors.businessemail = "Business email should be in correct format!";
        }
        if (Validator.isEmpty(data) && id=="businessaddress") {
            isValid = false;
            errors.businessaddress = "Business address field is required !";
        }
        else if(!regex_no.test(data) && id=="businessaddress"){
            isValid = false;
            errors.businessaddress = "Business address field should contain only alphabets!";
        }
        if (Validator.isEmpty(data) && id=="businesscontactnumber") {
            isValid = false;
            errors.businesscontactnumber = "Business contact number field is required !";
        }
        else if(!numbers.test(data) && id=="businesscontactnumber"){
            isValid = false;
            errors.businesscontactnumber = "Country field should contain only numbers!";
        }
        if (Validator.isEmpty(data)  && id=="businesscity") {
            isValid = false;
            errors.businesscity = "Business city field is required !";
        }
        else if(!letters.test(data)  && id=="businesscity"){
            isValid = false;
            errors.businesscity = "Business city field should contain only alphabets!";
        }
        if (Validator.isEmpty(data) && id=="businessregion") {
            isValid = false;
            errors.businessregion = "Business region field is required !";
        }
        else if(!letters.test(data) && id=="businessregion"){
            isValid = false;
            errors.businessregion = "Business region field should contain only alphabets!";
        }
        if (Validator.isEmpty(data) && id=="businesscountry") {
            isValid = false;
            errors.businesscountry = "Business country field is required !";
        }
        else if(!letters.test(data) && id=="businesscountry"){
            isValid = false;
            errors.businesscountry = "Business Country field should contain only alphabets!";
        }
        if (Validator.isEmpty(data) && id=="businesszipcode") {
            isValid = false;
            errors.businesszipcode = "Business zip code field is required !";
        }
        else if(!numbers.test(data) && id=="businesszipcode"){
            isValid = false;
            errors.businesszipcode = "Business zip code field should contain only numbers!";
        }
        if (Validator?.isEmpty(data)  && id=="idtype") {
            isValid = false;
            errors.idtype = "Document Type field is required !"
        }

        if (Validator?.isEmpty(data) && id=="idnumber") {
            isValid = false;
            errors.idnumber = "Id Number field is required !";
        }
        else if(!regex_no.test(data) && id=="idnumber"){
            isValid = false;
            errors.idnumber = "Id number field should be in correct format !";
        }
        if((!data) && id=="front") {
            isValid = false;
            errors.front = "Zip file is required !";
        }
        else if (!filevalidate(data) && id=="front") {
            isValid = false;
            errors.front = "Invalid file format !"
        }
    } else {
        if (Validator.isEmpty(data) && id=="ceoname") {
            isValid = false;
            errors.ceoname = "Ceo name field is required !";
        }
        else if(!letters.test(data) && id=="ceoname"){
            isValid = false;
            errors.ceoname = "Ceo name field should contain only alphabets!";
        }
        if (Validator.isEmpty(data) && id=="organizationname") {
            isValid = false;
            errors.organizationname = "Organization name field is required !";
        }
        else if(!letters.test(data) && id=="organizationname"){
            isValid = false;
            errors.organizationname = "Organization name field should contain only alphabets!";
        }
        if (Validator.isEmpty((data).toString()) && id=="nonprofitregistrationdate") {
            isValid = false;
            errors.nonprofitregistrationdate = "Registration date field is required !";
        }
        if (Validator.isEmpty(data) && id=="nonprofitorganizationaddress") {
            isValid = false;
            errors.nonprofitorganizationaddress = "Organization address field is required !";
        }
        else if(!regex_no.test(data) && id=="nonprofitorganizationaddress"){
            isValid = false;
            errors.nonprofitorganizationaddress = "Organization address field should be in correct format!";
        }
        if (Validator.isEmpty(data) && id=="nonprofitcity") {
            isValid = false;
            errors.nonprofitcity = "City field is required !";
        }
        else if(!letters.test(data) && id=="nonprofitcity"){
            isValid = false;
            errors.nonprofitcity = "City field should contain only alphabets!";
        }
        if (Validator.isEmpty(data) && id=="nonprofitregion") {
            isValid = false;
            errors.nonprofitregion = "Region name field is required !";
        }
        else if(!letters.test(data) && id=="nonprofitregion"){
            isValid = false;
            errors.nonprofitregion = "Region name field should contain only alphabets!";
        }
        if (Validator.isEmpty(data) && id=="nonprofitcountry") {
            isValid = false;
            errors.nonprofitcountry = "Country name field is required !";
        }
        else if(!letters.test(data) && id=="nonprofitcountry"){
            isValid = false;
            errors.nonprofitcountry = "Country name field should contain only alphabets!";
        }
        if (Validator.isEmpty(data) && id=="nonprofitzipcode") {
            isValid = false;
            errors.nonprofitzipcode = "Zip code field is required !";
        }
        else if(!numbers.test(data) && id=="nonprofitzipcode"){
            isValid = false;
            errors.nonprofitzipcode = "Zip code field should contain only numbers!";
        }
        if (Validator.isEmpty(data) && id=="nonprofitemailid") {
            isValid = false;
            errors.nonprofitemailid = "Email id field is required !";
        }
        else if(!emailRegex.test(data) && id=="nonprofitemailid"){
            isValid = false;
            errors.nonprofitemailid = "Email id field should contain only alphabets!";
        }
        if (Validator.isEmpty(data) && id=="nonprofitcontactnumber") {
            isValid = false;
            errors.nonprofitcontactnumber = "Contact number field is required !";
        }
        else if(!numbers.test(data) && id=="nonprofitcontactnumber"){
            isValid = false;
            errors.nonprofitcontactnumber = "Contact number field should contain only numbers!";
        }
        if (Validator.isEmpty(data) && id=="nonprofitwebsiteurl") {
            isValid = false;
            errors.nonprofitwebsiteurl = "Website Url field is required !";
        }
        else if(!url.test(data) && id=="nonprofitwebsiteurl"){
            isValid = false;
            errors.nonprofitwebsiteurl = "Website Url field should be in correct format!";
        }
        if (Validator?.isEmpty(data) && id=="idtype") {
            isValid = false;
            errors.idtype = "Document Type field is required !"
        }
        if (Validator?.isEmpty(data) && id=="idnumber") {
            isValid = false;
            errors.idnumber = "Id Number field is required !";
        }
        else if(!regex_no.test(data) && id=="idnumber"){
            isValid = false;
            errors.idnumber = "Id number field should be in correct format !";
        }
        if (Validator?.isEmpty(data) && id=="identitytype") {
            isValid = false;
            errors.identitytype = "Identity Type field is required !";
        }
        if((!data) && id=="front") {
            isValid = false;
            errors.front = "Zip file is required !";
        }
        else if (!filevalidate(data) && id=="front") {
            isValid = false;
            errors.front = "Invalid file format !"
        }
    }

    return {
        errors,
        isValid: isEmpty(errors)
    }
}

export const KYCSubmit = (data) => {
    console.log("data", data)
    var errors = {};
    var isValid = true;
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
    var letters =/^[A-Za-z\s]*$/;
    var numbers = /^[0-9]+$/;
    let regex_no = /^[ A-Za-z0-9_@./#&+-]*$/
    let url =/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
    if (Validator?.isEmpty(data?.identitytype)) {
        isValid = false;
        errors.identitytype = "Identity Type field is required !";
    }
    if (data?.identitytype == "Individual") {
        if (Validator.isEmpty(data?.emailid)) {
            isValid = false;
            errors.emailid = "Email Id field is required !";
        }
        else if(!emailRegex.test(data?.emailid)){
            isValid = false;
            errors.emailid = "Email Id field should be in correct format!";
        }
        if (Validator.isEmpty(data?.city)) {
            isValid = false;
            errors.city = "City field is required !";
        }
        else if(!letters.test(data?.city)){
            isValid = false;
            errors.city = "City field should contain only alphabets!";
        }
        if (Validator.isEmpty(data?.region)) {
            isValid = false;
            errors.region = "Region field is required !";
        }
        else if(!letters.test(data?.region)){
            isValid = false;
            errors.region = "Region field should contain only alphabets!";
        }
        if (Validator.isEmpty(data?.country)) {
            isValid = false;
            errors.country = "Country field is required !";
        }
        else if(!letters.test(data?.country)){
            isValid = false;
            errors.country = "Country field should contain only alphabets!";
        }
        if (Validator.isEmpty(data?.zipcode)) {
            isValid = false;
            errors.zipcode = "Zipcode field is required !";
        }
        else if(!numbers.test(data?.zipcode)){
            isValid = false;
            errors.zipcode = "Zipcode field should contain only numbers!";
        }
        if (Validator.isEmpty(data?.contactnumber)) {
            isValid = false;
            errors.contactnumber = "Contact number field is required !";
        }
        else if(!numbers.test(data?.contactnumber)){
            isValid = false;
            errors.contactnumber = "Contact number field should contain only numbers !";
        }
        if (Validator?.isEmpty(data.idtype)) {
            isValid = false;
            errors.idtype = "Document Type field is required !"
        }
        if (Validator?.isEmpty(data?.idnumber)) {
            isValid = false;
            errors.idnumber = "Id Number field is required !";
        }
        else if(!regex_no.test(data?.idnumber)){
            isValid = false;
            errors.idnumber = "Id number field should be in correct format !";
        }
        if (!data?.front) {
            isValid = false;
            errors.front = "Zip file is required !";
        }
        else if (!filevalidate(data?.front)) {
            isValid = false;
            errors.front = "Invalid file format !"
        }

    } else if (data?.identitytype == "Company") {
        if (Validator.isEmpty(data?.businessOwnerName)) {
            isValid = false;
            errors.businessOwnerName = "Business Owner Name field is required !";
        }
        else if(!letters.test(data?.businessOwnerName)){
            isValid = false;
            errors.businessOwnerName = "Business Owner Name field should contain only alphabets!";
        }
        if (Validator.isEmpty(data?.surname)) {
            isValid = false;
            errors.surname = "Surname field is required !";
        }
        else if(!letters.test(data?.surname)){
            isValid = false;
            errors.surname = "Surname field should contain only alphabets!";
        }
        if (Validator.isEmpty(data?.businessname)) {
            isValid = false;
            errors.businessname = "Business name field is required !";
        }
        else if(!letters.test(data?.businessname)){
            isValid = false;
            errors.businessname = "Business name field should contain only alphabets!";
        }
        if (Validator.isEmpty(data?.companynumber)) {
            isValid = false;
            errors.companynumber = "Company number field is required !";
        }
        else if(!regex_no.test(data?.companynumber)){
            isValid = false;
            errors.companynumber = "Company number field should be in correct format!";
        }
        if (Validator.isEmpty((data?.registrationdate).toString())) {
            isValid = false;
            errors.registrationdate = "Registration date field is required !";
        }
        if (Validator.isEmpty(data?.description)) {
            isValid = false;
            errors.description = "Description field is required !";
        }
        else if(!letters.test(data?.description)){
            isValid = false;
            errors.description = "Description field should contain only alphabets!";
        }
        if (Validator?.isEmpty(data?.websiteurl)) {
            isValid = false;
            errors.websiteurl = "Website Url field is required !";
        }
        else if(!url.test(data?.websiteurl)){
            isValid = false;
            errors.websiteurl = "Website Url field should be in correct format!";
        }
         if (Validator.isEmpty(data?.businessemail)) {
            isValid = false;
            errors.businessemail = "Business email field is required !";
        }
        else if(!emailRegex.test(data?.businessemail)){
            isValid = false;
            errors.businessemail = "Business email should be in correct format!";
        }
        if (Validator.isEmpty(data?.businessaddress)) {
            isValid = false;
            errors.businessaddress = "Business address field is required !";
        }
        else if(!regex_no.test(data?.businessaddress)){
            isValid = false;
            errors.businessaddress = "Business address field should contain only alphabets!";
        }
        if (Validator.isEmpty(data?.businesscontactnumber)) {
            isValid = false;
            errors.businesscontactnumber = "Business contact number field is required !";
        }
        else if(!numbers.test(data?.businesscontactnumber)){
            isValid = false;
            errors.businesscontactnumber = "Country field should contain only numbers!";
        }
        if (Validator.isEmpty(data?.businesscity)) {
            isValid = false;
            errors.businesscity = "Business city field is required !";
        }
        else if(!letters.test(data?.businesscity)){
            isValid = false;
            errors.businesscity = "Business city field should contain only alphabets!";
        }
        if (Validator.isEmpty(data?.businessregion)) {
            isValid = false;
            errors.businessregion = "Business region field is required !";
        }
        else if(!letters.test(data?.businessregion)){
            isValid = false;
            errors.businessregion = "Business region field should contain only alphabets!";
        }
        if (Validator.isEmpty(data?.businesscountry)) {
            isValid = false;
            errors.businesscountry = "Business country field is required !";
        }
        else if(!letters.test(data?.businesscountry)){
            isValid = false;
            errors.businesscountry = "Business Country field should contain only alphabets!";
        }
        if (Validator.isEmpty(data?.businesszipcode)) {
            isValid = false;
            errors.businesszipcode = "Business zip code field is required !";
        }
        else if(!numbers.test(data?.businesszipcode)){
            isValid = false;
            errors.businesszipcode = "Business zip code field should contain only numbers!";
        }
        if (Validator?.isEmpty(data.idtype)) {
            isValid = false;
            errors.idtype = "Document Type field is required !"
        }

        if (Validator?.isEmpty(data?.idnumber)) {
            isValid = false;
            errors.idnumber = "Id Number field is required !";
        }
        else if(!regex_no.test(data?.idnumber)){
            isValid = false;
            errors.idnumber = "Id number field should be in correct format !";
        }
        if (!data?.front) {
            isValid = false;
            errors.front = "Zip file is required !";
        }
        else if (!filevalidate(data?.front)) {
            isValid = false;
            errors.front = "Invalid file format !"
        }
    } else {
        if (Validator.isEmpty(data?.ceoname)) {
            isValid = false;
            errors.ceoname = "Ceo name field is required !";
        }
        else if(!letters.test(data?.ceoname)){
            isValid = false;
            errors.ceoname = "Ceo name field should contain only alphabets!";
        }
        if (Validator.isEmpty(data?.organizationname)) {
            isValid = false;
            errors.organizationname = "Organization name field is required !";
        }
        else if(!letters.test(data?.organizationname)){
            isValid = false;
            errors.organizationname = "Organization name field should contain only alphabets!";
        }
        if (Validator.isEmpty((data?.nonprofitregistrationdate).toString())) {
            isValid = false;
            errors.nonprofitregistrationdate = "Registration date field is required !";
        }
        if (Validator.isEmpty(data?.nonprofitorganizationaddress)) {
            isValid = false;
            errors.nonprofitorganizationaddress = "Organization address field is required !";
        }
        else if(!regex_no.test(data?.nonprofitorganizationaddress)){
            isValid = false;
            errors.nonprofitorganizationaddress = "Organization address field should be in correct format!";
        }
        if (Validator.isEmpty(data?.nonprofitcity)) {
            isValid = false;
            errors.nonprofitcity = "City field is required !";
        }
        else if(!letters.test(data?.nonprofitcity)){
            isValid = false;
            errors.nonprofitcity = "City field should contain only alphabets!";
        }
        if (Validator.isEmpty(data?.nonprofitregion)) {
            isValid = false;
            errors.nonprofitregion = "Region name field is required !";
        }
        else if(!letters.test(data?.nonprofitregion)){
            isValid = false;
            errors.nonprofitregion = "Region name field should contain only alphabets!";
        }
        if (Validator.isEmpty(data?.nonprofitcountry)) {
            isValid = false;
            errors.nonprofitcountry = "Country name field is required !";
        }
        else if(!letters.test(data?.nonprofitcountry)){
            isValid = false;
            errors.nonprofitcountry = "Country name field should contain only alphabets!";
        }
        if (Validator.isEmpty(data?.nonprofitzipcode)) {
            isValid = false;
            errors.nonprofitzipcode = "Zip code field is required !";
        }
        else if(!numbers.test(data?.nonprofitzipcode)){
            isValid = false;
            errors.nonprofitzipcode = "Zip code field should contain only numbers!";
        }
        if (Validator.isEmpty(data?.nonprofitemailid)) {
            isValid = false;
            errors.nonprofitemailid = "Email id field is required !";
        }
        else if(!emailRegex.test(data?.nonprofitemailid)){
            isValid = false;
            errors.nonprofitemailid = "Email id field should contain only alphabets!";
        }
        if (Validator.isEmpty(data?.nonprofitcontactnumber)) {
            isValid = false;
            errors.nonprofitcontactnumber = "Contact number field is required !";
        }
        else if(!numbers.test(data?.nonprofitcontactnumber)){
            isValid = false;
            errors.nonprofitcontactnumber = "Contact number field should contain only numbers!";
        }
        if (Validator.isEmpty(data?.nonprofitwebsiteurl)) {
            isValid = false;
            errors.nonprofitwebsiteurl = "Website Url field is required !";
        }
        else if(!url.test(data?.nonprofitwebsiteurl)){
            isValid = false;
            errors.nonprofitwebsiteurl = "Website Url field should be in correct format!";
        }
        if (Validator?.isEmpty(data.idtype)) {
            isValid = false;
            errors.idtype = "Document Type field is required !"
        }
        if (Validator?.isEmpty(data?.idnumber)) {
            isValid = false;
            errors.idnumber = "Id Number field is required !";
        }
        else if(!regex_no.test(data?.idnumber)){
            isValid = false;
            errors.idnumber = "Id number field should be in correct format !";
        }
        if (Validator?.isEmpty(data?.identitytype)) {
            isValid = false;
            errors.identitytype = "Identity Type field is required !";
        }
        if (!data?.front) {
            isValid = false;
            errors.front = "Zip file is required !";
        }
        else if (!filevalidate(data?.front)) {
            isValid = false;
            errors.front = "Invalid file format !"
        }
    }


    return {
        errors,
        isValid: isEmpty(errors)
    }
}



//validate file
const filevalidate = (image) => {
    console.log("image", image);
    var fileName = image?.name ? image?.name : image;
    var idxDot = fileName?.lastIndexOf(".") + 1;
    var extFile = fileName?.substr(idxDot, fileName.length).toLowerCase();
    console.log("extFiole", extFile);
    // || extFile=="jpeg" || extFile=="png" || extFile=="webp"
    if (extFile == "zip") {
        return true;
    } else {
        return false;
    }

}

export const validatelogin = (data) => {


    let errors = {};
    let isValid = true;
    if (Validator.isEmpty(data?.email)) {
        isValid = false;
        errors.email = "Email field is required";

    }
    else if (!Validator.isEmail(data?.email)) {
        isValid = false;
        errors.email = "Invalid email"
    }
    if (Validator.isEmpty(data.password)) {
        isValid = false;
        errors.password = "Password field is required";
    }

    return {
        errors,
        isValid: isEmpty(errors)

    };
};

export const validateemail = (data) => {

    let errors = {};
    let isValid = true;
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
    if (Validator.isEmpty(data?.email)) {
        isValid = false;
        errors.email = "Email field is required";
    }
    else if (!Validator.isEmail(data?.email)) {
        isValid = false;
        errors.email = "Invalid email"
    }
    else if(!emailRegex.test(data?.email)){
        isValid = false;
        errors.email = "Email Should be in correct format"
    }
    return {
        errors,
        isValid: isEmpty(errors)
    };
};


export const validatepassword = (data) => {
    let errors = {};
    let isValid = true;

    if (Validator.isEmpty(data?.password)) {
        isValid = false;
        errors.password = "Password field is required";
    }
    if (Validator.isEmpty(data?.confirmPass)) {
        isValid = false;
        errors.confirmPass = "Confirm password field is required";
    }
    else if (!Validator.equals(data?.password, data?.confirmPass)) {
        isValid = false;
        errors.confirmPass = "Passwords must match";
    }
    return {
        errors,
        isValid: isEmpty(errors)
    };
}

export const settingvalidate = (data) => {
    let errors = {};
    let isValid = true;
    if (Validator.isEmpty(data?.settingname)) {
        isValid = false;
        errors.settingname = "Setting name field is required";
    }
    if (Validator.isEmpty(data?.settingvalue)) {
        isValid = false;
        errors.settingvalue = "Setting Value field is required !";
    }
    return {
        isValid: isEmpty(errors),
        errors: errors
    }
}

export const validstandardtoken = (data) => {
    var rd = new RegExp(/^\d+$/);

    let isValid = true;
    let errors = {};
    if (Validator.isEmpty(data?.name)) {
        isValid = false
        errors.name = "Name field is required !"
    }
    if (Validator.isEmpty(data?.symbol)) {
        isValid = false;
        errors.symbol = "Symbol field is required !"
    }
    if (Validator.isEmpty(data?.decimal)) {
        isValid = false;
        errors.decimal = "Decimal field is required"
    }
    if (!rd.test(parseFloat(data?.decimal))) {
        isValid = false;
        errors.decimal = "Invalie Decimal field "
    }
    if (isNaN(parseFloat(data?.decimal))) {
        isValid = false;
        errors.decimal = "Invalid Decimal field"
    }
    if (Validator.isEmpty(data?.totalsupply)) {
        isValid = false;
        errors.totalsupply = "Total supply field is required"
    }

    if (isNaN(data?.totalsupply)) {
        isValid = false;
        errors.totalsupply = "Invalid Total supply field"
    }
    if (!rd.test(parseFloat(data?.totalsupply))) {
        isValid = false;
        errors.totalsupply = "Invalid Total supply field"
    }
    return {
        isValid: isEmpty(errors),
        errors: errors
    }
}

export const validliquiditytoken = (data) => {
    console.log("data", data);
    var rd = new RegExp(/^\d+$/);
    var rx = new RegExp(/^[-+]?[0-9]+\.[0-9]+$/)
    let isValid = true;
    let errors = {};
    if (Validator.isEmpty(data?.name)) {
        isValid = false
        errors.name = "Name field is required !"
    }
    if (Validator.isEmpty(data?.symbol)) {
        isValid = false;
        errors.symbol = "Symbol field is required !"
    }
    if (Validator.isEmpty(data?.decimal)) {
        isValid = false;
        errors.decimal = "Decimal field is required"
    }
    if (Validator.isEmpty(data?.totalsupply)) {
        isValid = false;
        errors.totalsupply = "Total supply field is required"
    }
    if (!rd.test(parseFloat(data?.totalsupply))) {
        isValid = false;
        errors.totalsupply = "Invalid Total supply field"
    }

    if (!rd.test(parseFloat(data?.decimal))) {
        isValid = false;
        errors.decimal = "Decimal field is required"
    }
    if (isNaN(parseFloat(data?.decimal))) {
        isValid = false;
        errors.decimal = "Invalid Decimal field"
    }


    if (isNaN(data?.totalsupply)) {
        isValid = false;
        errors.totalsupply = "Invalid Total supply field"
    }
    // if(!rd.test(parseFloat(data?.yieldfee)) && !rd.test(parseFloat(data?.yieldfee))){
    //     isValid = false;
    //     errors.yieldfee = "Transaction fee to generate yield  field is required"
    // }
    if (isNaN(parseFloat(data?.yieldfee))) {
        isValid = false;
        errors.yieldfee = "Invalid Transaction fee to generate yield .."
    }
    else {
        console.log("parseFloat(data?.yieldfee)", parseFloat(data?.yieldfee), parseFloat(data?.yieldfee) > 0 && parseFloat(data?.yieldfee) <= 100);
        if (!(parseFloat(data?.yieldfee) > 0 && parseFloat(data?.yieldfee) <= 100)) {
            isValid = false;
            errors.yieldfee = "Invalid Transaction fee to generate yield"
        }
    }

    // if (!rd.test(parseFloat(data?.liquidityfee)) && !rd.test(parseFloat(data?.liquidityfee))) {
    //     isValid = false;
    //     errors.liquidityfee = "Transaction fee to generate liquidity  field is required"
    // }
    if (isNaN(parseFloat(data?.liquidityfee))) {
        isValid = false;
        errors.liquidityfee = "Invalid Transaction fee to generate liquidity "
    }
    else if (!(parseFloat(data?.liquidityfee) > 0 && parseFloat(data?.liquidityfee) <= 100)) {
        isValid = false;
        errors.liquidityfee = "Transaction fee to generate liquidity must be 0-100"
    }

    // if (rd.test(parseFloat(data?.maximumtransaction)) || rd.test(parseFloat(data?.maximumtransaction))) {
    //     isValid = false;
    //     errors.maximumtransaction = "Maximum transaction Amount field is required"
    // }
    if (isNaN(parseFloat(data?.maximumtransaction))) {
        isValid = false;
        errors.maximumtransaction = "Invalid Maximum transaction Amount field "
    }
    else if (!(parseFloat(data?.maximumtransaction) > 0 && parseFloat(data?.maximumtransaction) <= 100)) {
        isValid = false;
        errors.maximumtransaction = "Maximum Transaction  must be 0-100"
    }

    // if (!rd.test(parseFloat(data?.exchangethresold)) && !rd.test(parseFloat(data?.exchangethresold))) {
    //     isValid = false;
    //     errors.exchangethresold = "Exchange Thersold field is required"
    // }
    if (isNaN(parseFloat(data?.exchangethresold))) {
        isValid = false;
        errors.exchangethresold = "Invalid Exchange Thersold field "
    }
    else if (!(parseFloat(data?.exchangethresold) > 0 && parseFloat(data?.exchangethresold) <= 100)) {
        isValid = false;
        errors.exchangethresold = "Exchange Thresold  must be 0-100"
    }
    if (Validator.isEmpty(data?.yieldfee)) {
        isValid = false;
        errors.yieldfee = "Transaction fee to generate yield  field is required"
    }
    if (Validator.isEmpty(data?.liquidityfee)) {
        isValid = false;
        errors.liquidityfee = "Transaction fee to generate liquidity  field is required"
    }
    if (Validator.isEmpty(data?.maximumtransaction)) {
        isValid = false;
        errors.maximumtransaction = "Maximum transaction Amount field is required"
    }
    if (Validator.isEmpty(data?.exchangethresold)) {
        isValid = false;
        errors.exchangethresold = "Exchange Thersold field is required"
    }
    return {
        isValid: isEmpty(errors),
        errors: errors
    }
}

export const validtokenlock = (data) => {
    console.log("data", data);
    let isValid = true;
    let errors = {};
    if (Validator.isEmpty(data?.tokenaddress)) {
        isValid = false
        errors.tokenaddress = "Token Address field is required !"
    }
    if (Validator.isEmpty(data?.amount)) {
        isValid = false
        errors.amount = "Amount field is required !"
    }

    if (data.vesting) {
        if (Validator.isEmpty(data?.cycle)) {
            isValid = false
            errors.cycle = "Cycle field is required !"
        }
        if (Validator.isEmpty(data?.cyclepercent)) {
            isValid = false
            errors.cyclepercent = "Cycle release percent  field is required !"
        }
        if (data?.cyclepercent < 0 && data?.cyclepercent >= 100) {
            isValid = false
            errors.cyclepercent = "Cycle release percent  field value must be 0 to 100 !"
        }
        // if(Validator.isEmpty(data?.tgedate)){
        //     isValid = false
        //     errors.tgedate = "TGE date  field is required !"
        // }
        // if(data.tgedate )
        // if(Validator.isEmpty(data?.tgepercent)){
        //     isValid = false
        //     errors.tgepercent = "TGE Percent  field value must be 0 to 100 !"
        // }
        // if(data?.tgepercent > 0 && data?.tgepercent <=100){
        //     isValid = false
        //     errors.tgepercent = "TGE Percent  field value must be 0 to 100 !"
        // }
    }
    else {
        if (Validator.isEmpty(data?.locktime)) {
            isValid = false
            errors.locktime = "Lock Until field is required !"
        }
    }
    if (data?.anotherowner) {
        if (Validator.isEmpty(data?.anotherowneraddress)) {
            isValid = false;
            errors.anotherowneraddress = "Owner field is required !";
        }

    }
    return {
        isValid: isEmpty(errors),
        errors: errors
    }
}

export const validprivatesalestep1 = (data) => {
    let isValid = true;
    let errors = {};
    if (Validator.isEmpty(data?.title)) {
        isValid = false
        errors.title = "Title field is required !"
    }
    return {
        isValid: isEmpty(errors),
        errors: errors
    }
}

export const validprivatesalestep2 = (data) => {
    let isValid = true;
    let errors = {};
    if (Validator.isEmpty(data?.whitelist)) {
        isValid = false
        errors.whitelist = "Token Address field is required !"
    }
}


export const urlvalidation = (data) => {
    const regex = new RegExp(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/);
    console.log("valid url ", regex.test(data));
    return regex.test(data);
}


export const addwhitelistvalidation = async (val) => {
    let isValid = true;
    // var errors ={} ;
    let ob = {}
    console.log("val", val);
    // data.map(async(val , i)=>{
    if (Validator.isEmpty(val.user)) {
        isValid = false;
        ob.user = "Wallet Address field is required !";
    }
    else {
        let isvalid = await IsValidAddress(val.user);
        console.log("isvalid", isvalid);
        if (!isvalid) {
            isValid = false
            ob.user = "Invalid wallet address !"
        }
    }

    if (isNaN(parseFloat(val.bnbvalue)) || val.bnbvalue <= 0) {
        isValid = false
        ob.bnbvalue = "Invalid BNB value !"
    }
    // errors[i]=ob;
    // ob = {}
    // })
    // errors = Object.keys(errors)
    console.log("length", ob);
    return {
        isValid: isEmpty(ob),
        errors: ob
    }
}

export const removewhitelistvalidation = async (val) => {
    let isValid = true;
    // var errors ={} ;
    let ob = {}
    console.log("val", val);
    // data.map(async(val , i)=>{
    if (Validator.isEmpty(val.user)) {
        isValid = false;
        ob.user = "Wallet Address field is required !";
    }
    else {
        let isvalid = await IsValidAddress(val.user);
        console.log("isvalid", isvalid);
        if (!isvalid) {
            isValid = false
            ob.user = "Invalid wallet address !"
        }
    }

    // if (isNaN(val.bnbvalue) || validliquiditytoken.bnbvalue != 0) {
    //     isValid = false
    //     ob.bnbvalue = "Invalid BNB value !"
    // }
    // errors[i]=ob;
    // ob = {}
    // })
    // errors = Object.keys(errors)
    console.log("length", ob);
    return {
        isValid: isEmpty(ob),
        errors: ob
    }
}


export const trendingvalidate = (data) => {
    let errors = {};
    let isValid = true;
    if (Validator.isEmpty(data?.saleaddress)) {
        isValid = false;
        errors.saleaddress = "Saleaddress field is required";
    }
    if (Validator.isEmpty(data?.displayname)) {
        isValid = false;
        errors.displayname = "Display name field is required !";
    }
    if (Validator.isEmpty(data?.status)) {
        isValid = false;
        errors.status = "Status field is required !";
    }
    if (Validator.isEmpty(data?.position)) {
        isValid = false;
        errors.status = "Position field is required !";
    }
    if (Validator.isEmpty(data?.date)) {
        isValid = false;
        errors.status = "Date field is required !";
    }
    return {
        isValid: isEmpty(errors),
        errors: errors
    }
}

export const auditkycvalidate = (data) => {
    let errors = {};
    let isValid = true;
    if (Validator.isEmpty(data?.audit)) {
        isValid = false;
        errors.audit = "Audit field is required";
    }
    if (!urlvalidation(data?.audit)) {
        isValid = false;
        errors.audit = "Invalid audit url";
    }
    if (Validator.isEmpty(data?.status)) {
        isValid = false;
        errors.status = "Status field is required !";
    }

    if (Validator.isEmpty(data?.kyc)) {
        isValid = false;
        errors.kyc = "Kyc field is required";
    }
    if (!urlvalidation(data?.kyc)) {
        isValid = false;
        errors.kyc = "Invalid kyc url";
    }

    return {
        isValid: isEmpty(errors),
        errors: errors
    }
}