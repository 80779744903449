import React, { Component } from "react";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import Header from "../Header";
import Sidebar from "../Sidebar";
import Cookies from 'universal-cookie';

import "../../css/styles.css";

import logo_grad from "../../images/Bitdeal/logos.png";


import {
  Container,
  Row,
  Col,
  Card,
  ProgressBar,
  InputGroup,
  FormControl,
  Tab,
  Nav
} from "react-bootstrap";


import { validateemail } from "../../hooks/kycvalidation";
import { forgetPassword } from "../../hooks/usebackend";
import toast from "react-hot-toast";
const cookies = new Cookies();
class Forgotpassword extends Component {


  constructor(props) {
    super(props);
    this.state = {
      email:'',
      errors : {}

    }


  }


  handleSubmit = async()=>{
    let payload = {
      email : this.state.email,
    }
  
    let validate = await validateemail(payload);
    if(validate.isValid){
      var forPass = await forgetPassword(payload);
      console.log("forPass",forPass)
      if(forPass?.data?.data?.success == "false" || !forPass?.data?.data?.success){
        this.setState({"errors" :forPass?.data?.data?.errors})
      }else {
        toast.success(forPass?.data?.data?.message)
        console.log("forPass_forPass" , forPass);
      }
     
    }
    else{
      this.setState({"errors" : validate?.errors})
    }
  }


  render() {


    return (
      <div id="loader_main">

        <div className="logo_overlay" id="logo_overlay" style={{ opacity: 1 }}>
          {/* <Header /> */}

          <div className="whole_sec login_screen pb-5">
            <div>

              <Container className="container">
                <div className="coming_soon login_text">
                  <div className='row'>
                    <div className='col-10 col-sm-8 col-md-8 col-lg-9 mx-auto'>
                      <div className="text-center">
                        <img src={logo_grad} className="img-fluid logo_grad_width" />
                      </div>
                      <div className='card_bg card mt-5'>
                        <div className='card-body'>
                          <div className='row'>

                            <div className='col-12 col-md-12 mb-3'>


                              <p class="text-white">Forgot Password</p>
                              <hr class="hr_green mb-0"></hr>

                            </div>


                            <div className='col-12 col-md-12 mt-3 mb-0'>

                              <p className='input_desc_sm'>Email Address</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="email" placeholder="Enter your email"
                                    aria-describedby="basic-addon2"
                                    onChange={(e) => {
                                      this.setState({ "email": e?.target?.value })
                                    }}
                                  />

                                </InputGroup>
                                <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.email}</span>
                              </div>
                            </div>


                            <div className='col-12 col-md-12 mt-3 mb-0'>
                              <div className="text-right">
                                <Link to="/login" className="link_ui_admin">Back to Login?</Link>
                              </div>
                            </div>

                            <center className='mt-4 mx-auto'>
                              <button className="get-started-btn mr-3" onClick={()=>{this.handleSubmit()}}>Submit</button>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    );
  }
}

export default Forgotpassword;
