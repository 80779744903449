import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';

import Adminheader from './Adminheader';
import Adminsidebar from './Adminsidebar';
import Editsettingmodal from "./Editsettingmodal";
import Addsettingmodal from "./Addsettingmodal";
import ClaimViewModal from "./ClaimViewModal";


import Web3 from "web3";
import '../../css/styles.css';

import coin from "../../images/coin.png"
import favicon from "../../images/Bitdeal/logos.png"

import loader from "../../images/loader.gif"
import { Dropdown, Nav, Tab, Row, Col, Container, ProgressBar, InputGroup, Form } from 'react-bootstrap';
import { getsettinghook } from '../../hooks/usebackend';
import { DisableAirdrop, GetAirdropdetail, Gettotalairdrops, Getuserairdropsize ,UseTokenInfo} from '../../hooks/useContract';

const cookies = new Cookies();
class AdminAirdrop extends Component {

  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.5;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");


  }

  hideLoader() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");
  }

  componentDidMount() {
    //   this.restrict()
    // this.showLoader();
    this.hideLoader();
    this.handleloadairdrop()

  }

  restrict = async () => {
    var email = cookies.get('cryp-launch-admin-email');
    console.log("email", email);
    if (!email) {
      this.props.history.push("/login")
    }
  }

  getsetting = async () => {
    let payload = {
      skip: this.state.skip,
      limit: this.state.limit
    }
    let setting = await getsettinghook(payload);
    console.log("setting", setting?.data?.data);
    this.setState({ "settings": setting?.data?.data });
    let count = Math.ceil(setting?.data?.count / this.state.limit)
    this.setState({ "pagecount": count });
    // if(count < 5){
    //   this.setState({"stepcount" : count})
    // }
  }


  handlenextpage = async () => {
    let payload = {
      limit: this.state.limit,
      skip: this.state.skip + this.state.limit
    }
    let result = await getsettinghook(payload);
    this.setState({ "settings": result?.data?.data })
    if (this.state.currentpage % 5 == 0) {
      this.setState({ "button": this.state.button + 5 })
      // if(this.state.pagecount )
    }
    this.setState({ "skip": this.state.skip + this.state.limit })
    this.setState({ "currentpage": this.state.currentpage + 1 })
  }

  handleprevpage = async () => {
    let payload = {
      limit: this.state.limit,
      skip: this.state.skip - this.state.limit
    }
    let result = await getsettinghook(payload);
    this.setState({ "settings": result?.data?.data })
    if ((this.state.currentpage - 1) % 5 == 0) {
      this.setState({ "button": this.state.button - 5 })
      // if(this.state.pagecount )
    }
    this.setState({ "skip": this.state.skip - this.state.limit });
    this.setState({ "currentpage": this.state.currentpage - 1 });
  }

  handlenumpage = async (val) => {
    this.setState({ "currentpage": parseInt(val) })
    let payload = {
      limit: this.state.limit,
      skip: (parseInt(val) * 10) - 10
    }

    let result = await getsettinghook(payload);
    this.setState({ "settings": result?.data?.data });
    this.setState({ "skip": (parseInt(val) * 10) - 10 });
  }

  constructor(props) {
    super(props);
    this.state = {
      editsettingModal: false,
      addsettingModal: false,
      claimViewModal:false,
      settings: [],
      singlesetting: {},

      skip: 0,
      limit: 10,
      pagecount: 0,
      button: 0,
      currentpage: 1,

      stepcount: 5,


      //airdrop
      totalairdropcount: 0,
      airdrop: [],
      airdropstart: 0,
      airdroplimit: 3,
      airdropproxy: [],

      //singleuserairdrop
      singleuserairdrop: [],
      singleuserairdropcount: 0,
      singlestart: 0,
      singlelimit: 3,
      singleuserairdropproxy: [],
      airdropAddress :""
    };
  }

  //Airdrop
  // async handleloadairdrop() {
  //   this.showLoader();
  //   var total = await Gettotalairdrops();

  //   console.log("total airdrop", total);
  //   if (total.length > 0) {
  //     var newdata = await this.handleload(this.state.airdropstart, this.state.airdropstart + this.state.airdroplimit, total.length, total);
  //     console.log("newdata", newdata);
  //     this.setState({ "airdrop": newdata, "airdropstart": this.state.airdropstart + this.state.airdroplimit, "totalairdropcount": total.length, "airdropproxy": total });
  //   }
  //   this.hideLoader();
  // }

  async handleloadairdrop() {
    this.showLoader();
    var total = await Gettotalairdrops();


    console.log("total airdrop", total);
    if (total.length > 0) {
      var newdata = await this.handleload(this.state.airdropstart, this.state.airdropstart + this.state.airdroplimit, total.length, total);
      console.log("newdata", newdata);
      for (let i = 0; i < newdata.length; i++) {
        let tokendata = await UseTokenInfo(newdata[i].token);
        newdata[i] = { ...newdata[i], ...{ ['decimal']: tokendata.decimals } }
        console.log("tokendata", tokendata, newdata[i])
      }



      this.setState({ "airdrop": newdata, "airdropstart": this.state.airdropstart + this.state.airdroplimit, "totalairdropcount": total.length, "airdropproxy": total });
    }


    this.hideLoader();
  }

  async handleload(start, end, total, totalproxy) {
    let value = total ? total : this.state.totalairdropcount;
    let proxydata = totalproxy ? totalproxy : this.state.airdropproxy;
    var newarray = [];
    console.log("start", start, value);
    for (var i = start; i < end; i++) {
      if (i < value) {
        console.log("addresssss>>>", proxydata[i]);
        let newairdrop = await GetAirdropdetail(proxydata[i]);
        newarray.push(newairdrop);
      } else {
        return newarray;
      }
    }
    return newarray;
  }


  
  async handleloadmoreairdrop() {
    this.setState({ "isLoading": true })
    let newdata = await this.handleload(this.state.airdropstart, this.state.airdropstart + this.state.airdroplimit);
    console.log("newdata", newdata);
    for (let i = 0; i < newdata.length; i++) {
      let tokendata = await UseTokenInfo(newdata[i].token);
      newdata[i] = { ...newdata[i], ...{ ['decimal']: tokendata.decimals } }
      console.log("tokendata", tokendata, newdata[i])
    }
    const total = this.state.airdrop.concat(newdata);
    this.setState({ "airdrop": total, "airdropstart": this.state.airdropstart + this.state.airdroplimit, "isLoading": false });
  }

  async loadsingleuserairdrop() {
    console.log("fuctioncall");
    this.showLoader();
    const singluserproxy = await Getuserairdropsize();
    if (singluserproxy.length > 0) {
      var newdata = await this.handleload(this.state.singlestart, this.state.singlestart + this.state.singlelimit, singluserproxy.length, singluserproxy);
      console.log("newdata", newdata);
      this.setState({ "singleuserairdrop": newdata, "singlestart": this.state.singlestart + this.state.singlelimit, "singleuserairdropcount": singluserproxy.length, "singleuserairdropproxy": singluserproxy });
    }
    this.hideLoader();
  }

  async handlesingleuserload(start, end, total, totalproxy) {
    let value = total ? total : this.state.singleuserairdropcount;
    let proxydata = totalproxy ? totalproxy : this.state.singleuserairdropproxy;
    var newarray = [];
    console.log("start", start, value);
    for (var i = start; i < end; i++) {
      if (i < value) {
        console.log("addresssss>>>", proxydata[i]);
        let newairdrop = await GetAirdropdetail(proxydata[i]);
        newarray.push(newairdrop);
      } else {
        return newarray;
      }
    }
    return newarray;
  }

  async handledisable(index) {
    let result = await DisableAirdrop(index);
    window.location.reload();
  }

  //airdrop end

  onDismiss() {
    this.setState({ editsettingModal: false });
  }

  onDismissAdd() {
    this.setState({ addsettingModal: false });
  }

  onDismissView() {
    this.setState({ claimViewModal: false });
  }

  render() {


    const location = this.props.location.pathname.split('/')[1];
    console.log("location",location)

    const { editsettingModal, addsettingModal, claimViewModal } = this.state

    return (
      <div id="loader_main">
        <div id="loader_div">
          <span className="spin_round">

          </span>
          <img src={favicon} className="logo_load" />
        </div>
        <div className='logo_overlay' id="logo_overlay">


          <Adminheader />

          <div className="whole_sec pb-5">
            <div className='flex_side_right'>
              <Adminsidebar />
              {/* ongoing_sec */}
              <div className='right_side_sec'>

                <div className="right_side_spacing">
                  <div className='ongoing_sec proj_bg pb-5'>
                    <div className="container px-1">
                      <div className='top_heqad_sec mt-5 mt-md-0'>

                        <div className="row">
                          <div className="col-12 col-md-6 flex_cen_col mt-md-5 mb-md-4">
                            {/* <p className="banner_subtitle form_subhead">Settings List</p> */}
                            <div className="row">
                              <div className="col-12 col-md-12 flex_cen_col mt-md-5 mb-md-4 flex_row_cen">
                                <p className="banner_subtitle form_subhead">Airdrop List</p>
                                <p className="banner_subtitle form_subhead_1">Total Records: {this?.state?.airdropproxy?.length}</p>

                                {/* <hr className='hr_yellow mt-5'/> */}
                              </div>

                            </div>




                            {/* <hr className='hr_yellow mt-5'/> */}
                          </div>
                          {/* <div className="col-12 col-md-6 flex_cen_col mt-md-5 mb-md-4">
                        <div className='text-right'>
            <button className="get-started-btn btn_width_auto" onClick={() => this.setState({ addsettingModal: true })}>Add</button>
            </div>
                        </div> */}

                        </div>
                      </div>
                    </div>

                    <div className="inner_bg">
                      <div className="container px-1">
                        <Tab.Container defaultActiveKey="second">
                          <Row className="container-fluid w-100 mx-0 px-0">


                            <Col lg={12} className="img_center_lg px-0">
                              <div className='container container_custom px-0'>
                                <Tab.Content>

                                  <Tab.Pane eventKey="second">
                                    {this.state.airdrop.length == 0 && <p className='no_data_text'>No Data Found</p>}
                                    <div class="table-responsive">
                                      {!this.state.airdrop.length == 0 &&
                                        <table className="table recepients_table">
                                          <thead>
                                            <tr>
                                              <th>Address</th>
                                              <th>Airdrop Title</th>
                                              <th>Total Tokens</th>
                                              <th>Tokens per user</th>
                                              <td>Claimed Users</td>
                                              <td></td>
                                            </tr>
                                          </thead>
                                          {this.state.airdrop && this.state.airdrop.map((data, i) => <>
                                            <tr>
                                              <td>{console.log("single airdrop", data)}
                                          

                                                <div className="p-0 table_det">
                                                  <span className="table_text">{data?.airdropaddress}</span>

                                                </div>
                                              </td>
                                              {console.log("airdrop", data.totalInvested,data?.decimal)}
                                              <td>{data?.social[0]}</td>
                                              <td>{data?.totalInvested / 10 ** parseFloat(data?.decimal)}</td>
                                              <td>{data?.amountPerHead / 10 ** parseFloat(data?.decimal)}</td>
                                              <td><a className="link_text" onClick={() => {
                                                this.setState({claimViewModal:true,airdropAddress:data?.airdropaddress})
                                              }
                                              }>View</a></td>
                                              <td className="d-flex justify-content-end"><a className="link_text" onClick={() => {
                                                this.handledisable(i);
                                              }
                                              }>Disable</a></td>

                                            </tr>
                                          </>
                                          )}



                                          {/* <tr>
      <td>
     
    
        <div className="p-0 table_det">
          <span className="table_text">Lorem Ipsum</span>
    
      </div>
      </td>
      <td>Lorem Ipsum</td>
      <td className="d-flex justify-content-end"><a className="link_text" onClick={() => this.setState({ editsettingModal: true })}>Edit</a></td>
      
    </tr>
    <tr>
      <td>
     
    
        <div className="p-0 table_det">
          <span className="table_text">Lorem Ipsum</span>
    
      </div>
      </td>
      <td>Lorem Ipsum</td>
      <td className="d-flex justify-content-end"><a className="link_text" onClick={() => this.setState({ editsettingModal: true })}>Edit</a></td>
      
    </tr> */}

                                        </table>}
                                    </div>
                                    {console.log("airdrop",(this?.state?.airdrop?.length < this?.state?.airdropproxy?.length))}
                                    <div className='text-center mt-5'>
                                      {((this?.state?.airdropproxy?.length > 3 ) && (this?.state?.airdrop?.length < this?.state?.airdropproxy?.length) ) ?
                                        <button className="get-started-btn" onClick={this.handleloadmoreairdrop.bind(this)} id="load_more_btn">{this.state.isLoading ? 'Loading...' : 'View More Pools'}</button> :""
                                      }
                                    </div>
                                  </Tab.Pane>


                                </Tab.Content>
                              </div>
                            </Col>
                          </Row>
                        </Tab.Container>


                      </div>
                    </div>
                  </div>
                  {/* end ongoing_sec */}

                </div>
              </div>
            </div>
          </div>
          {editsettingModal && <Editsettingmodal data={this.state.singlesetting} onDismiss={() => this.onDismiss()} getset={() => this.getsetting()} />}
          {addsettingModal && <Addsettingmodal onDismiss={() => this.onDismissAdd()} getset={() => this.getsetting()} />}

          {claimViewModal && <ClaimViewModal onDismiss={() => this.onDismissView()} airdropAddress={this.state.airdropAddress}/>}



          {/* <Footer /> */}
        </div>
      </div>
    )
  }
}

export default AdminAirdrop