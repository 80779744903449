import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import Header from '../Header';
import Sidebar from '../Sidebar';
import Trendingslider from '../trendingslider';

import { getlaunchpaddatalimit, getLaunchpadsaleCardLimit, getmycontributiondatalimit, getPrivatesaleCardLimit, getSaleCards, getSaleCardsLimit, getsaledatalimit, getTotalSalesInfo, searchCards, searchSale } from '../../hooks/useProjects';
import { gettrendingdisplayhook, getusecreatedlaunchhook, getuserdatahook } from '../../hooks/usebackend';
import { getSaleInfoCard } from "../../hooks/useContract";
import { getAccount, getChainId, setChainId } from '../../hooks/useAccount'

import '../../css/styles.css';

import favicon from "../../images/Bitdeal/logo_curve.png";

import img_user1 from "../../images/img1.jpg";
import img_user2 from "../../images/img2.jpg";
import img_user3 from "../../images/img3.jpg";


import eth from "../../images/eth.png";
import DAI from "../../images/DAI.png";
import USDC from "../../images/USDC.png";
import USDT from "../../images/USDT.png";

import polygon from "../../images/polygon.png";
import moment from 'moment';
import { weekcalculation } from '../../helper/weekCal'


import { Dropdown, Nav, Tab, Row, Col, Container, InputGroup, ProgressBar, Form } from 'react-bootstrap';


class Leaderboardlist extends Component {



  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.5;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");


  }

  hideLoader() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");



  }

  showActive(e)
  {
    var elements = document.querySelectorAll(".nav_green_scroll_tab .nav-link");
    
    for(var i=0;i<elements.length; i++)
    {
      // console.log(elements[i],"eelle")
      elements[i].classList.remove("active_el");
    }
    // console.log(e.target,"lem")
    e.target.parentNode.classList.add("active_el");

  }


  componentDidMount() {

    this.hideLoader();

    // this.getuserdata();
    this.gettrending();
    this.loadData();


    document.getElementById("first0").classList.add("active_el");

    // this.gettrendingData();
    console.log("totalSales", this.state.totalSales);
  }


  // getuserdata = async () => {
  //   const totalsale = await getTotalSalesInfo();
  //   let userdata = await getuserdatahook(this.state.accountInfo && this.state.accountInfo.toLowerCase());
  //   console.log("userdata", userdata.data.data);
  //   // this.setState({investedpools : userdata.data.data.investedpools})
  //   // this.setState({wishlist : userdata.data.data.wishlist})
  //   // this.setState({viewlist : userdata.data.data.viewlist})
  //   // this.setState({investedamount : userdata.data.data.investedamount})
  //   // const totalproxydata = userdata.data.data.launchpad.filter(data => data.iswhitelist == true)
  //   // console.log("totalproxydata" , totalproxydata);
  //   let totalproxydata = userdata.data.data.launchpad;
  //   const newly = totalproxydata.filter(data => data.iswhitelist == false || data.iswhitelist == "false")
  //   let newarray = [];
  //   newly.map((val, i) => {
  //     let newvalue = totalsale.find((e) => e._sale == val.saleaddress);
  //     newarray.push(newvalue)
  //   })
  //   console.log("userdatanewly", newly, newarray);
  //   this.setState({ mycontribution: newarray.reverse() })
  // }

  async gettrending() {
    let data = await gettrendingdisplayhook();
    console.log("dataa trending", data?.data?.data);
    if (data?.data?.data?.length > 0)
      this.setState({ trending: data?.data?.data })
  }



  constructor(props) {
    super(props);
    this.state = {
      percentage: 66,
      proxy: [],
      totalSales: [],
      onGoingSales: [],
      upComingSales: [],
      isSearch: false,
      searchSale: [],
      search: '',
      interval: 3,
      loadInterval: 3,
      searchInterval: 3,
      searchProxy: [],
      isLoading: false,
      currentChain: 0,
      closeHcmenu: false,
      closeCoinmenu: false,
      closeKycmenu: false,
      closeStatusmenu: false,
      isuser: false,
      button: true,
      mycontribution: [],
      accountInfo: getAccount(),
      interval: 0,
      loadinterval: 3,
      original: [],
      filterdata: "All Status",
      sortdata: "No Filter",
      coin: [],
      status: [],
      type: [],
      trending: [],
      dummylaunchpad: [],
      trending: [],
      filterSales: [],
      viewMore: true,
      arrayofWeek: 20,
      filteredSales: [],
      sumofEarned: 0,
      successSale: 0
    }

  }

  async loadData() {
    this.showLoader();
    const Proxydata = await getTotalSalesInfo();
    console.log("Proxydata", Proxydata);
    // const totalproxydata = Proxydata.filter(data => data._isWhitelisted == false)
    let trenddata = await gettrendingdisplayhook();
    console.log("dataa trending", trenddata?.data?.data);
    if (trenddata?.data?.data?.length > 0)
      this.setState({ trending: trenddata?.data?.data })
    var trending = trenddata?.data?.data
    console.log("trendingdataaaaaaaaaaaaaa", trending);
    let newtotalproxy = [];
    trending?.map((val, i) => {
      let singledata = Proxydata.find(e => e?._sale == val.saleaddress);
      if (singledata) {
        newtotalproxy.push(singledata)
      }
    })
    //add on works
    // let dummylaunchpad = await getusecreatedlaunchhook();
    // var dummylaunch = [];
    // console.log("get dummy launchpad" , dummylaunchpad);
    // if(dummylaunchpad?.data?.type == "success"){
    //   dummylaunch = dummylaunchpad?.data?.data?.filter(data => data?.whitelist != "privatesale");
    //   this.setState({dummylaunchpad : dummylaunch})
    //   newtotalproxy = dummylaunch ? newtotalproxy.concat(dummylaunch) : []
    // }



    Proxydata.map((datas, i) => {
      let singlesale = newtotalproxy.find(e => e._sale == datas?._sale);
      if (!singlesale) {
        newtotalproxy.push(datas)
      }
    })
    this.setState({ proxy: newtotalproxy })
    console.log("trendingdataaaaaaaaaaaaaa", newtotalproxy);
    console.log("total proxy data", Proxydata);
    // this.setState({ proxy : totalproxydata })
    this.setState({ original: newtotalproxy })
    const total = await getlaunchpaddatalimit(newtotalproxy, 0, newtotalproxy.length);
    console.log("total>>>", total);
    // const total = await getPrivatesaleCardLimit(Proxydata,0,this.state.interval , this.state.isuser);
    // console.log("button " ,Proxydata?.length , total.index );
    // if(Proxydata?.length == total.index || !total){
    //   this.setState({button : false})
    // }
    // await this.sleep(1000)
    //  this.setState({ totalSales: total.saleInfoCards });  
    //  this.setState({ interval: total.index+1 }); 
    this.setState({ totalSales: total });
    this.setState({ interval: this.state.loadinterval });
    this.hideLoader();
    this.filterdata(weekcalculation(0), 0)
  }

  async loadMore() {
    console.log("Lad moreee", this.state.totalSales.length < this.state.proxy.length)
    this.setState({ isLoading: true });
    const newly = await getlaunchpaddatalimit(this.state.proxy, this.state.interval, this.state.interval + this.state.loadInterval);
    console.log("newly ", newly);
    // if(newly.length === 0){
    //   this.setState({"viewMore":false})
    // }
    const total = this.state.totalSales.concat(newly);
    this.setState({ totalSales: total, interval: this.state.interval + this.state.loadInterval });
    this.setState({ isLoading: false });
  }


  // async gettrendingData(){
  //   const totalsale = await getTotalSalesInfo();
  //   console.log("totalsale",totalsale);
  //   var totalproxy = [];
  //   totalsale && totalsale.map(async(val , i)=>{
  //     let data = await getSaleInfoCard(val._sale);
  //     // if(data?.LaunchpadType)
  //     // let deposittoken = data?.presaleRate * data?.hardCap/10**18;
  //     // (hardCap / liquidityPercent) * 100;
  //     // let unsoldtoken =  await getUnsoldtokentrending(data?.saleAddress);
  //     // console.log("unsoldtoken" , unsoldtoken);
  //     // console.log("depositt oken" , deposittoken);
  //     // let trend = deposittoken - (unsoldtoken/10**18);
  //     // var obj2 = {...data, trend : trend}
  //     // Object.assign(data , {trend : trend})
  //     // data.trend = trend;
  //     totalproxy.push(data);
  //   })
  //   // console.log("totalproxy" , totalproxy);
  //   // totalproxy.sort(
  //   //   (p1, p2) => (p1.trend < p2.trend) ? 1 : (p1.trend > p2.trend) ? -1 : 0);
  // //  var sortedtotalproxy =  totalproxy.sort((a, b) => parseFloat(a.participants) - parseFloat(b.participants))
  // var sortedtotalproxy = await totalproxy.sort((a, b) => (parseFloat(a.participants) > parseFloat(b.participants)) ? 1 : -1);
  //     console.log("totalproxy" , sortedtotalproxy);
  //     this.setState({trending : sortedtotalproxy});
  // }

  async filterdata(data, i) {
    console.log("data", data, i, this.state.totalSales)
    let filterdata = [];
    let Sales = this.state.totalSales.filter(values => {
      let currentDate = new Date(parseFloat(values.startTime) * 1000)
      console.log(currentDate, "currentDate")
      var year = new Date(currentDate.getFullYear(), 0, 1)
      var days = Math.floor((currentDate - year) / (24 * 60 * 60 * 1000));
      var week = Math.ceil(days / 7);
      let val = week + "/" + currentDate.getFullYear()
      console.log("val == data", val == data, val, data)
      return val == data
    })
    let datafilter =  Sales.sort(function (a, b) {
      console.log(parseFloat(a.earnedCap) - parseFloat(b.earnedCap), "sort")
      return ((parseFloat(b.earnedCap)/ 10 ** 18) - (parseFloat(a.earnedCap)/ 10 ** 18))
    });
    console.log(Sales,"Sales_filterdata",datafilter)

    let sumofEarned = Sales.reduce((total, currentValue) => {
      return total = parseFloat(total) + parseFloat(currentValue.earnedCap);

    }, 0)
    this.setState({ sumofEarned: (sumofEarned) / 10 ** 18 })

    let successSale = Sales.filter(values => {
      console.log("values.softCap > values.earnedCap", values.softCap > values.earnedCap)
      return values.earnedCap > values.softCap

    })
    this.setState({ successSale: successSale.length })

    console.log("Sales", Sales, successSale)
    // TradeHoursData.sort(function (a, b) {
    //   console.log(parseFloat(a.day) - parseFloat(b.day))
    //          return parseFloat(a.day) - parseFloat(b.day);
    //   });
    this.setState({ filteredSales: Sales })
    console.log("Sales_filterdata", Sales, this.state.filteredSales)
  }



  render() {


    const location = this.props.location.pathname.split('/')[1];


    return (
      <div id="loader_main">
        <div id="loader_div">
          <span className="spin_round">

          </span>
          <img src={favicon} className="logo_load" />
        </div>
        <div className='logo_overlay' id="logo_overlay">


          <Header />

          <div className="whole_sec pb-5">
            <div className='flex_side_right'>
              <Sidebar />
              {/* ongoing_sec */}
              <div className='right_side_sec'>

                <Trendingslider />
                <div className="right_side_spacing">

                  <div className='ongoing_sec proj_bg pb-5'>
                    <div className="container px-1">
                      <div className='top_heqad_sec private_sec_bg mt-5 mt-md-0'>
                        <div className="bg_outline1">

                        </div>
                        <div className="row">
                          <div className="col-12 col-md-12 flex_cen_col mt-md-5 mb-md-4">
                            <p className="banner_subtitle form_subhead">Leaderboard List</p>


                          </div>

                        </div>

                        <div className="row airlists">
                          <div className="col-12 col-md-12 flex_cen_col mt-md-3 mb-md-3">
                            <div className='card_bg card'>
                              <div class="card-body">
                                <div className='row'>
                                  <div className="col-6 col-md-6 text-center">
                                    <p className='airtitle'>TOTAL SUCCESS THIS WEEK</p>
                                    <h2 className='airno'>{this.state.successSale}</h2>
                                  </div>
                                  <div className="col-6 col-md-6 text-center">
                                    <p className='airtitle'>TOTAL RAISED THIS WEEK</p>
                                    <h2 className='airno'>{this.state.sumofEarned}</h2>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>


                      </div>
                    </div>


                    <div className="inner_bg mt-4">
                      <div className="container px-1">


                        <Tab.Container defaultActiveKey="first0">
                          <Row className="container-fluid w-100 mx-0 px-0">


                            <Col xs={12} sm={12} xl={12} className="px-0">
                              <Nav variant="pills" className="nav_green nav_green_scroll_tab">

                                {[...Array(this.state.arrayofWeek)].map((val, index) => {
                                  return (
                                    <Nav.Item onClick={(e) => { this.filterdata(weekcalculation(index), index); this.showActive(e) }}>
                                      <Nav.Link eventKey={"first0"} id={"first"+index}>
                                        <p className='mb-0'>Week {weekcalculation(index)}</p>
                                      </Nav.Link>
                                    </Nav.Item>
                                  )
                                })}
                                {/* <Nav.Item>
                                  <Nav.Link eventKey="second2" id="second2">
                                    <p className='mb-0'>Week {weekcalculation(1)}</p>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="third3" id="third3">
                                    <p className='mb-0'>Week {weekcalculation(2)}</p>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="four4" id="four4">
                                    <p className='mb-0'>Week {weekcalculation(3)}</p>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="five5" id="five5">
                                    <p className='mb-0'>Week {weekcalculation(4)}</p>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="six6" id="six6">
                                    <p className='mb-0'>Week {weekcalculation(5)}</p>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="seven7" id="seven7">
                                    <p className='mb-0'>Week {weekcalculation(6)}</p>
                                  </Nav.Link>
                                </Nav.Item> */}

                              </Nav>
                            </Col>

                            <Col xs={5} sm={3} xl={2}>
                              {/* <Dropdown className='dropdown_normal mt-2' >
                                <Dropdown.Toggle variant="success" id="dropdown-basic" >
                                  All Chain
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='dropdown_normal_menu'>
                                  <Dropdown.Item><p>All Chain</p></Dropdown.Item>
                                  <Dropdown.Item><p>BSC</p></Dropdown.Item>
                                  <Dropdown.Item><p>Ethereum</p></Dropdown.Item>
                                  <Dropdown.Item><p>Polygon</p></Dropdown.Item>
                                  <Dropdown.Item><p>Avalanche</p></Dropdown.Item>
                                  <Dropdown.Item><p>Fantom</p></Dropdown.Item>

                                </Dropdown.Menu>
                              </Dropdown> */}
                            </Col>
                            <hr class="hr_yellow mt-0 mt-3 mb-4" />
                            <Col lg={12} className="img_center_lg px-0">
                              <div className='container container_custom px-0'>
                                <Tab.Content>
                                  <Tab.Pane eventKey="first0">
                                    <div className='airlists'>
                                      <div class="table-responsive">
                                        <table className="table table_leadrlist">


                                          {console.log("this.state.filteredSales", this.state.filteredSales, this.state.filteredSales.length > 0)}
                                          {this.state.filteredSales.length > 0 ?
                                            this.state.filteredSales?.map((val, i) => {
                                              console.log("this.state.filteredSales2", this.state.filteredSales, this.state.filteredSales.length > 0)
                                              // {val.earnedCap && val.participants ? :"No data found"}
                                              try {
                                                return (


                                                  <tr>
                                                    <td>


                                                      <div className="p-0 table_det">
                                                        <div className='text_img_td'>
                                                          <span className="table_text">0{i + 1}</span>
                                                          <div className='img_overlp_div ml-3'>
                                                            <img src={val?.logo} className='img-fluid img_user' />
                                                            <img src={val?.coin === "BNB" ? eth : val.coin === "DAI" ? DAI : val.coin === "USDT" ? USDT : val.coin === "USDC" ? USDC : ""} className='img-fluid overlap_icon' />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>


                                                    <td>
                                                      <p className='mb-0'><span className='td_leader_bold'>{val.name}</span> <span className='td_leader_sm pl-1'>{val.symbol}</span></p>
                                                      <p className='mb-0'><span className='td_leader_theme'>{val.presaleRate} {val.coin}</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'>{moment(parseFloat(val.startTime) * 1000).format('YYYY/MM/DD HH:MM')}</span></p>

                                                    </td>

                                                    <td className='text-right'>
                                                      <div className='text_img_td'>
                                                        <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                          <CircularProgressbar value={val && parseInt(parseInt(val.earnedCap) / parseInt(val.softCap) * 100)} text={`${val && parseInt(parseInt(val.earnedCap) / parseInt(val.softCap) * 100)}%`} />
                                                        </div>
                                                        <Link to={val?.isWhitelisted === true ? `/privatesaledetail/${val?.saleAddress}` : `/launchpaddetail/${val?.saleAddress}`} className="get-started-btn">View Pool</Link>

                                                      </div>
                                                    </td>

                                                  </tr>

                                                )
                                              }

                                              catch (err) {
                                                console.log("err",err)
                                              }
                                            }) :

                                            <p className='text-center mt-3 nodata_tet'>No data found</p>
                                          }


                                          {/* <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">02</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user2} className='img-fluid img_user' />
                                                    <img src={polygon} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>


                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">03</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user3} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>


                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">04</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user1} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>

                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">05</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user2} className='img-fluid img_user' />
                                                    <img src={polygon} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>


                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">06</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user3} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>


                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">07</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user1} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>

                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">08</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user2} className='img-fluid img_user' />
                                                    <img src={polygon} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>

                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">09</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user3} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>

                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">10</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user1} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr> */}



                                        </table>
                                      </div>



                                    </div>

                                  </Tab.Pane>

                                  <Tab.Pane eventKey="second2">
                                    <div className='airlists'>
                                      <div class="table-responsive">
                                        <table className="table table_leadrlist">

                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">01</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user1} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>

                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">02</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user2} className='img-fluid img_user' />
                                                    <img src={polygon} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>


                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">03</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user3} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>


                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">04</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user1} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>

                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">05</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user2} className='img-fluid img_user' />
                                                    <img src={polygon} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>




                                        </table>
                                      </div>



                                    </div>

                                  </Tab.Pane>

                                  <Tab.Pane eventKey="third3">
                                    <div className='airlists'>
                                      <div class="table-responsive">
                                        <table className="table table_leadrlist">

                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">01</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user1} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>

                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">02</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user2} className='img-fluid img_user' />
                                                    <img src={polygon} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>


                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">03</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user3} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>


                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">04</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user1} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>

                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">05</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user2} className='img-fluid img_user' />
                                                    <img src={polygon} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>




                                        </table>
                                      </div>



                                    </div>

                                  </Tab.Pane>

                                  {/* 
                                  <Tab.Pane eventKey="four4">
                                    <div className='airlists'>
                                      <div class="table-responsive">
                                        <table className="table table_leadrlist">

                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">01</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user1} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>

                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">02</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user2} className='img-fluid img_user' />
                                                    <img src={polygon} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>


                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">03</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user3} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>


                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">04</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user1} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>

                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">05</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user2} className='img-fluid img_user' />
                                                    <img src={polygon} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>




                                        </table>
                                      </div>



                                    </div>

                                  </Tab.Pane> */}

                                  {/* <Tab.Pane eventKey="five5">
                                    <div className='airlists'>
                                      <div class="table-responsive">
                                        <table className="table table_leadrlist">

                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">01</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user1} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>

                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">02</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user2} className='img-fluid img_user' />
                                                    <img src={polygon} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>


                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">03</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user3} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>


                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">04</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user1} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>

                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">05</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user2} className='img-fluid img_user' />
                                                    <img src={polygon} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>




                                        </table>
                                      </div>



                                    </div>

                                  </Tab.Pane> */}

                                  {/* <Tab.Pane eventKey="six6">
                                    <div className='airlists'>
                                      <div class="table-responsive">
                                        <table className="table table_leadrlist">

                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">01</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user1} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>

                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">02</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user2} className='img-fluid img_user' />
                                                    <img src={polygon} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>


                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">03</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user3} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>


                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">04</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user1} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>

                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">05</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user2} className='img-fluid img_user' />
                                                    <img src={polygon} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>




                                        </table>
                                      </div>



                                    </div>

                                  </Tab.Pane> */}

                                  <Tab.Pane eventKey="seven7">
                                    <div className='airlists'>
                                      <div class="table-responsive">
                                        <table className="table table_leadrlist">

                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">01</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user1} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>

                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">02</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user2} className='img-fluid img_user' />
                                                    <img src={polygon} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>


                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">03</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user3} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>


                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">04</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user1} className='img-fluid img_user' />
                                                    <img src={eth} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>

                                          <tr>
                                            <td>


                                              <div className="p-0 table_det">
                                                <div className='text_img_td'>
                                                  <span className="table_text">05</span>
                                                  <div className='img_overlp_div ml-3'>
                                                    <img src={img_user2} className='img-fluid img_user' />
                                                    <img src={polygon} className='img-fluid overlap_icon' />
                                                  </div>
                                                </div>
                                              </div>
                                            </td>


                                            <td>
                                              <p className='mb-0'><span className='td_leader_bold'>YTK</span> <span className='td_leader_sm pl-1'>YTK</span></p>
                                              <p className='mb-0'><span className='td_leader_theme'>60 BNB</span> <span className='td_leader_sm'>– Listing time:</span><span className='td_leader_theme'> 2023.08.22 23:48</span></p>

                                            </td>

                                            <td className='text-right'>
                                              <div className='text_img_td'>
                                                <div style={{ width: 50, height: 50 }} className='mr-3'>
                                                  <CircularProgressbar value={this.state.percentage} text={`${this.state.percentage}%`} />
                                                </div>
                                                <Link to="/" className="get-started-btn">View Pool</Link>

                                              </div>
                                            </td>

                                          </tr>




                                        </table>
                                        {/* <> */}
                                        {/* {totalSales && totalSales.length < this.state.proxy.length ? */}
                                        {/* <button className="get-started-btn" onClick={this.loadMore.bind(this)} id="load_more_btn">{this.state.isLoading ? 'Loading...' : 'View More Pools'}</button> */}
                                        {/* : <></> */}
                                        {/* }
                                       </> */}
                                      </div>



                                    </div>

                                  </Tab.Pane>


                                </Tab.Content>
                              </div>
                            </Col>

                          </Row>
                        </Tab.Container>
                      </div>
                    </div>




                  </div>
                  {/* end ongoing_sec */}

                  {/* upcoming_sec */}
                  <div className='text-center mb-5'>
                    {console.log(this.state.totalSales.length, "this.state.totalSales.length")}
                    {this.state.totalSales && this.state.totalSales.length < this.state.proxy.length ? <button className="get-started-btn" onClick={this.loadMore.bind(this)} id="load_more_btn">{this.state.isLoading ? 'Loading...' : 'View More'}</button> : ""}
                  </div>
                  <Container className='pb-4 px-0'>

                    <center>
                      <small className="mt-0 bottom_text">
                        Disclaimer: The information provided shall not in any way
                        constitute a recomendation as to whether you should invest
                        in any product discussed. We accept no liability for any
                        loss occasioned to any person acting or refraining from
                        action as a result of any material provided or published.
                      </small>
                    </center>
                  </Container>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    )
  }
}

export default Leaderboardlist