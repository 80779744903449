import React, { Component } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Papa from "papaparse";

import { useContext, useEffect, useState } from "react";
import { InputGroup, FormControl,Form, Container } from 'react-bootstrap';

import CsvModal from './CsvModal';
import ViewcsvModal from './ViewcsvModal';
import { Approvetoken, Checkaddress, checkbalnceTokens, Checksum, Multisend, MultisendApprovetoken } from '../../hooks/useContract';
// import { filereader } from '../../hooks/excelreader';
import { CHAINS } from '../../config/env';
import { getAccount, getChainId } from '../../hooks/useAccount';
import isEmpty from 'is-empty';
import * as XLSX from 'xlsx';
import { processCSV } from '../../hooks/excelreader';




  
class CreatetabMultisender extends Component
{

       
    constructor(props) {
        super(props);
        this.state = { 
            currentStep:1,  
            csvModal:false,
            viewcsvModal:false,
            button1: true,
            tokeninfo : {},
            errors : {},
            address : "",
            approvebutton: false,
            allocateuser : "",
            users : [],
            amounts : [],
            fileuser : [],
            fileamount : [],
            filename : "",
            approvemax : true,
            balance : 0,
            refbal : 0,
            values : []
        };
    }

    onDismiss(){
        this.setState({ csvModal: false });
    }

    onDismissView(){
        this.setState({ viewcsvModal: false });
    }

    async handlechange(data){
      const usersplit = data.split(/\r?\n/);
      let user = [];
      let amount = [];
      console.log("usersplit" , usersplit);
      await usersplit?.map((e , i) =>{
        let singleuser = e?.split(/(\s+)/);
        console.log("handle change" , singleuser , singleuser[singleuser?.length -1]);
        // const ad = await Checksum(singleuser[0].trim())
        user.push(singleuser[0]);
        const ref = parseFloat(singleuser[singleuser?.length -1])
        const nwamt = ((parseFloat(ref)*(10**this.state.tokeninfo?.decimals)).toString())
        console.log("new amount" , ref ,nwamt , 10**this.state.tokeninfo?.decimals);
        amount.push(nwamt);
      })
      this.setState({users : user , amounts : amount , values: usersplit});
      
      console.log("handle change " , user , amount);
    }

    async upload(){
      let user = [];
      let amount = [];
      let dataparse = JSON.parse(localStorage.getItem("dataparse"));
      
      console.log("Dataparrse" , dataparse );
      await dataparse.map((m , i) => {
        console.log("m" , m , m.amount);
        var keys = Object.keys(m);
        console.log("keys" ,  keys);
        user.push(m[keys[0]]);
        amount.push(m[keys[1]]?.trim());
    })
    console.log("user" , user , amount);
      // this.setState({fileuser : JSON.parse(localStorage.getItem("user")) , fileamount : JSON.parse(localStorage.getItem("amount")) , allocateuser : JSON.parse(localStorage.getItem("data")),
      //     users : JSON.parse(localStorage.getItem("user")) , amounts : JSON.parse(localStorage.getItem("amount"))})
      this.setState({fileuser : user , fileamount : amount , allocateuser : JSON.stringify(dataparse),
      users : user , amounts : amount})
          // localStorage.setItem("user" , "")
          //   localStorage.setItem("amount" , "")
          //   localStorage.setItem("data" , "")
    }

    async approvetoken() {
      let total = 0
      if(this?.state?.allocateuser){
        this?.state?.amounts && await this?.state?.amounts?.map((data)=>{
          console.log("total" , data , this.state?.amounts , this.state?.address);
          total = total + parseFloat(data);
        })
        if(parseFloat(total) <= parseFloat(this?.state?.refbal)){
          if(this.state?.approvemax )
          await MultisendApprovetoken(this.state.address , "115792089237316195423570985008687907853269984665640564039457584007913129639935");
          else
          await MultisendApprovetoken(this.state.address , "115792089237316195423570985008687907853269984665640564039457584007913129639935");
          this.setState({ approvebutton: true })
        }
        else{console.log("toal" , total);
          toast.error("Insufficient Funds")
        }
      }
      else{
        toast.error("Allocation Field is required !")
      }
  }

  


    async settokenaddress(value) {
      this.setState({ address: value });
      // this.getTokenInfo(e.target.value);
      const tokendata = await Checkaddress(value);
      console.log("tokendata", tokendata);
      if (tokendata.isValid) {
        const bal = await checkbalnceTokens(value , getAccount())
        this.setState({ refbal: bal })
          this.setState({ button1: false })
          this.setState({ tokeninfo: tokendata.tokeninfo })
          this.setState({ errors: {} });
          this.setState({balance : parseFloat(bal)/10**parseFloat(tokendata?.tokeninfo?.decimals)})
          // this.setState({ approvebutton: tokendata.allowance })
      }
      else {
          let formData = { ...this.state.errors, ["tokenaddress"]: "Invalid token address !" };
          this.setState({ errors: formData });
          this.setState({ tokeninfo: {} })
          this.setState({ button1: true })
      }
  }

  async handlesend(){
    if(this.state.allocateuser){
      var temp = []
      for(var i = 0 ; i < this?.state?.users?.length ; i++){
        let ref = {address : this?.state?.users[i] , amount : this?.state?.amounts[i]/10**this.state.tokeninfo?.decimals}
        temp.push(ref);
      }
      this.setState({values : temp});
      var result = await Multisend(this?.state?.users , this?.state?.amounts , this?.state?.address)
      this.setState({currentStep : 2})
      // window.location.reload();
    }
    else{
      toast.error("Allocation Field is required !")
    }
  }
  processCSV(str, delim=','){
    const headers = str.slice(0,str.indexOf('\n')).split(delim);
    const rows = str.slice(str.indexOf('\n')+1).split('\n');
  console.log("rows" , rows);
    const newArray = rows.map( row => {
        const values = row.split(delim);
        const eachObject = headers.reduce((obj, header, i) => {
            obj[header] = values[i];
            return obj;
        }, {})
        return eachObject;
    })
  
    return (newArray)
  }
  

  filereader = async(e) => {
    try{
        e.preventDefault();
        
        var files = e.target.files, f = files[0];
        console.log("readed data" , files);
        var reader = new FileReader();
        reader.onload =   async function (e) {
          console.log("e" , e);
            var data = e.target.result;
            var dataParse = processCSV(data);
            console.log("dataparse" , dataParse);
    localStorage.setItem("dataparse" , JSON.stringify(dataParse))

          
        }
        // reader.readAsBinaryString(f)
        // promiseA.then(()=>console.log("async"))
        // this.setState({fileuser : user , fileamount : amount , allocateuser : data,
        //       users : user , amounts : amount})
        // this.setState({fileuser : JSON.parse(localStorage.getItem("user")) , fileamount : JSON.parse(localStorage.getItem("amount")) , allocateuser : JSON.parse(localStorage.getItem("data")),
        //   users : JSON.parse(localStorage.getItem("user")) , amounts : JSON.parse(localStorage.getItem("amount"))})
    }
    catch(e){
        console.log("error on file reader" , e);
    }
}

changeHandler = async (event) => {
  let splitFile = event.target.files[0].name.split(".");
  console.log("splitFile", splitFile)
  if (splitFile[splitFile.length - 1] != "csv") {
      toast.error('Select your csv file !');
      return false
  }
  const valuesArray = [];
  this.setState({ 'filename': event.target.files[0].name })
  Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        console.log("result" , results);
          // this.setState({ valuesArray: results.data })

          // Iterating data to get column name and their values
          // results.data.map((d) => {
          //     console.log("===============", Object.keys(d));
          //     valuesArray.push(Object.values(d));
          // });

          // Filtered Values
          let user = [];
        let amount = [];
        // let dataparse = JSON.parse(localStorage.getItem("dataparse"));
      
      // console.log("Dataparrse" , dataparse );
      await results?.data?.map((m , i) => {
        console.log("m" , m , m.amount);
        var keys = Object.keys(m);
        console.log("keys" ,  keys);
        user.push(m[keys[0]]);
        amount.push((m[keys[1]]?.trim())*(10**this.state.tokeninfo?.decimals));
    })
    console.log("user" , user , amount);
      // this.setState({fileuser : JSON.parse(localStorage.getItem("user")) , fileamount : JSON.parse(localStorage.getItem("amount")) , allocateuser : JSON.parse(localStorage.getItem("data")),
      //     users : JSON.parse(localStorage.getItem("user")) , amounts : JSON.parse(localStorage.getItem("amount"))})
      this.setState({fileuser : user , fileamount : amount , allocateuser : JSON.stringify(results?.data),
      users : user , amounts : amount})
          this.setState({ values: results?.data })
      },
  });
}


    render() {
      return (
        <div>
{this.state.csvModal && <CsvModal onDismiss={()=>this.onDismiss()} /> }
{this.state.viewcsvModal && <ViewcsvModal val = {this?.state?.values} onDismiss={()=>this.onDismissView()} /> }


   {/* Wizard */}
   <div className='d-none d-xl-block container px-1'>
           <div className='row mt-5 card_bg_wizard_row_he'>
             <div className='col-6'>
               <div className={this.state.currentStep ==1 ? "card_bg_wizard active card":"card_bg_wizard card"} >
               {/* onClick={()=>{this.setState({ currentStep: 1})}} */}
                 <div className='card-body'>
                   <div className='d-flex align-items-center h-100'>
                     <div className='wizard_badge_num'>
                       1
                     </div>
                     <div className='pl-3 create_wiz_desc'>
                       <h5>Add Your Allocation</h5>
                       <p className='mb-0'>Enter your token to be send with allocations</p>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
             <div className='col-6'>
               <div className={this.state.currentStep ==2 ? "card_bg_wizard active card":"card_bg_wizard card"} >
               {/* onClick={()=>{this.setState({ currentStep: 2})}} */}
                 <div className='card-body'>
                   <div className='d-flex align-items-center h-100'>
                     <div className='wizard_badge_num'>
                       2
                     </div>
                     <div className='pl-3 create_wiz_desc'>
                       <h5>Confirmation</h5>
                       <p className='mb-0'>Let review your information</p>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
             
          

         </div>
           </div>
          
           {/* end Wizard */}
      {/* ongoing_sec */}
          <div className='ongoing_sec form_sec'>
      
      
       <div className="inner_bg mt-5">
         <div className='row'>
           <div className='col-12 col-md-10 col-lg-9 mx-auto'>
           <div className="tab_img">
                 <div className='card_bg card'>
            <div className='card-body'>
              <div className='row align-items-center pb-4'>
                <div className='col-12 col-md-6 mb-3 mb-md-0'>
                  <p className='bottom_text mb-0'>(*) is required field.</p>
                  
                </div>
                <div className={this.state.currentStep ==1 ? "col-12 col-md-6 text-md-right d-block":"col-12 col-md-6 text-md-right d-none"}>
                </div>
              </div>
              <div id="firststep" className={this.state.currentStep ==1 ? "d-block":"d-none"}>
                <div className='row'>                     
                      <div className='col-12 col-md-12'>
                    <p className='input_desc_sm'>Token Address</p>
                    <div className="inputs input-groups">
                        <InputGroup className="">
                            <FormControl placeholder="Ex. 0x..." 
                             id="tokenaddress" 
                                aria-describedby="basic-addon2"
                                value={this.state.address} onChange={(e) => this.settokenaddress(e.target.value)}
                            />
                        </InputGroup>
                        <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.tokenaddress}</span>
                    </div>
                    <div className='note_desc mt-3 mb-3'>
                    <p>Multi-sender allows you to send ERC20 token in batch by easiest way. You can enter token address to send specific token or leave it blank to send chain token such as BNB....</p>
                    </div>
                        </div>

                       {!isEmpty(this?.state?.tokeninfo) && <div className='col-12 col-md-12 mb-3'>
                        <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
            <span className='desc_grey_txt'>Name :</span>
            <span className='desc_grey_txt font_12'>{this.state.tokeninfo?.name}
            </span>
            </p> 

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
            <span className='desc_grey_txt'>Symbol :</span>
            <span className='desc_grey_txt font_12'>{this.state.tokeninfo?.symbol}
            </span>
            </p> 

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
            <span className='desc_grey_txt'>Decimals :</span>
            <span className='desc_grey_txt font_12'>{this.state.tokeninfo?.decimals}
            </span>
            </p> 

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
            <span className='desc_grey_txt'>Balance :</span>
            <span className='desc_grey_txt font_12'>{this?.state?.balance}
            </span>
            </p> 
                        </div>}

                        <div className='col-12 col-md-12'>
                    <p className='input_desc_sm'>Allocations*</p>
                    <div className="inputs input-groups text_are_grp text_area_norla_colr resize_appra">
                        <InputGroup className="">
                        <textarea  
                            id="allocations" rows="10"
                                aria-describedby="basic-addon2"
                                placeholder='Insert allocation: separate with breaks line. By format: address,amount or address amount
                                Ex:
                                0x0000000000000000000000000000000000001000 13.45
                                0x0000000000000000000000000000000000001000 1.049
                                0x0000000000000000000000000000000000001000 1'
                                value={this.state?.allocateuser}
                                onChange={(e)=> {this.setState({allocateuser : e?.target?.value}); this.handlechange(e?.target?.value)}}
                            />
                        </InputGroup>
                        <span className="text-danger f-12 d-block text-left"></span>
                    </div>
                    <div className='note_desc my-3'>
                    <p>Recipients allocation is required</p>
                    </div>
                        </div>

                        <div className='col-12 col-md-12 mb-3'>
                        <div className="w-100">

                          <div className='upload_btn_alignkr'>

                        <div className="inputs input-groups w-100">
                                      <InputGroup className="file_grp_input">
                                        <div className="w-100">
                                          <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" 
                                            onChange={async(e)=> { 
                                              var result =   this.changeHandler(e); 
                                              // let user = [];
                                              // let amount = [];
                                              // await result?.dataParse?.map((m , i) => {
                                              //     user.push(m.address);
                                              //     amount.push(m.amount);
                                              // })
                                            //  this.setState({fileuser : result?.user , fileamount : result?.amount , filename : e?.target?.files[0]?.name , allocateuser : result?.ref,
                                            //   users : result?.user , amounts : result?.amount});
                                              this.setState({filename : e?.target?.files[0]?.name})
                                              // console.log("result" , result);
                                            
                                            }}
                                            />
                                            <label className="custom-file-label mb-0" for="inputGroupFile04">
                                              {this?.state?.filename ? this?.state?.filename : "Choose CSV file"}</label>
                                          </div>
                                           
                                        </div>

                                      </InputGroup>
                                      <span className="text-danger"></span>
                                    </div>

                                    {/* <button className="get-started-btn upload_krbtn" onClick={() =>this.upload()}>upload</button> */}
                                    </div>

                                        </div>
                        </div>
                        <div className='col-12 col-md-12 mb-3'>

                    <a href="javascript:void(0)" className='csv_modal_green_text' onClick={()=>{this.setState({csvModal:true})}}>Sample CSV file</a>
                   
                     
                     </div>

                    
                     <div className='col-12 col-md-12 mb-3'>
                        <p className='input_desc_sm'>Amount to approve</p>
                      
                        <div className="custom-control custom-radio mb-2">
                    <input type="checkbox" id="customRadio1" name="customRadio" className="custom-control-input"  value ={5}
                    checked = {this.state?.approvemax}
                   onChange = {(e)=>this.setState({approvemax : e?.target?.checked})}
                    />
                    <label className="custom-control-label" for="customRadio1">Unlimited amount</label>
                    </div>   

                    <div className="custom-control custom-radio">
                    <input type="checkbox" id="customRadio2" name="customRadio" value={2} className="custom-control-input" 
                   checked = {!this.state?.approvemax}
                   onChange = {(e)=>this.setState({approvemax : !e.target.checked})}
                    />
                    <label className="custom-control-label" for="customRadio2">Exact amount</label>
                    </div>                  
                        </div>

                        <div className='col-12 col-md-12 mb-4'>
                            <div className='card_footer_form'>
                            <p className='mb-0'>{`Please exclude ${CHAINS[getChainId()].MULTISEND_ADDRESS}  from fees, rewards, max tx amount to start sending tokens.`}</p>
                            </div>
                        </div>


                        

                        <div className='col-12 col-md-12 text-center'>
                        {this.state.approvebutton && <button className="get-started-btn" onClick={async()=>{await this.handlesend()}}
                        disabled = {this.state.button1}
                        >
                           Send
                        </button>}

                             {!this.state.approvebutton &&<button className="get-started-btn" onClick={async()=>{await this.approvetoken()}}
                             disabled = {this.state.button1}
                             >
                                Approve
                             </button> }

                       
                        </div>

             </div>
             </div>
             <div id="secondstep" className={this.state.currentStep ==2 ? "d-block":"d-none"}>
                <div className='row'>
                

<div className='col-12 col-md-12'>
<p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
            <span className='desc_grey_txt'>Token Address :</span>
            <span className='desc_grey_txt font_12'>{this.state.address}
            </span>
            </p>
            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
            <span className='desc_grey_txt'>Name :</span>
            <span className='desc_grey_txt font_12'>{this.state.tokeninfo?.name}
            </span>
            </p> 

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
            <span className='desc_grey_txt'>Symbol :</span>
            <span className='desc_grey_txt font_12'>{this.state.tokeninfo?.symbol}
            </span>
            </p> 

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
            <span className='desc_grey_txt'>Decimals :</span>
            <span className='desc_grey_txt font_12'>{this.state.tokeninfo?.decimals}
            </span>
            </p> 

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
            <span className='desc_grey_txt'>Balance :</span>
            <span className='desc_grey_txt font_12'>{this?.state?.balance}
            </span>
            </p>

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
            <span className='desc_grey_txt'>Allocations :</span>
            <span className='desc_grey_txt font_12'>
            <a href="javascript:void(0)" className='csv_modal_green_text' onClick={()=>{this.setState({viewcsvModal:true})}}>View Allocations</a>

           </span>
            </p>

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
            <span className='desc_grey_txt'>Amount to approve :</span>
            <span className='desc_grey_txt font_12'>{this?.state?.approvemax ? "unlimited amount" : "Exact amount"}
            </span>
            </p> 
</div>




                    
                    <div className='col-12 col-md-12 text-center'>
                        <button className="get-started-btn mr-2" onClick={()=>{window.location.reload()}}>
                           Done
                        </button>
                        {/* <button className="get-started-btn">
                           Confirm
                        </button> */}
                        </div>
                </div>
            </div>


                      
             </div>
             </div>
            </div>
            </div>
        

                
               
        </div>
           </div>
         </div>

        
        
       </div>
     


    
       
      )
    }

}


export default CreatetabMultisender