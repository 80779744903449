import bsc from "../images/bsc.png"
import polygon from "../images/polygon.png"
import bsctestnet from "../images/bsctestnet.png"
import eth from "../images/eth.png"
import ropsten from "../images/ropsten.png"
// export const RPC_URL = "https://bsc-dataseed1.binance.org/";
// export const CHAIN_ID = 56;
  
export const RPC_URL = "https://binance.llamarpc.com";
 export const CHAIN_ID = 56;
 
 export const ONEDAYINSECONDS = 86400;
 export const frontendurl  =     window.location.origin;

 export const userFee = 5

 export const userFeeWithtoken = 4

 export const CHAINS = [

    {
        NAME: "BSC-Mainnet",
        RPC_URL:"https://binance.llamarpc.com",
        CHAIN_ID:56,
        IMAGE: bsctestnet,
        ROUTER: "0x29035dEbC68181B33183E3A7fb9291bC2815644F", //pancake
        // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
        LIQUIDITY_TOKEN_ADDRESS : "0xF09F8B159Cb5A73f1a0c33EA7A6B0aa8d6529fc6",
        STANDARD_TOKEN_ADDRESS : "0x7CA46c6f794d0019f5115eB920E107c6ED1a2Bde",
        LOCK_ADDRESS : "0x32b920B59DA1E7Dce2a308f6AFf0cd7d76d48448", //0x643C924f71E413cC031Cca4C2f60f602A1310792", //0xa44a318F00322FeF490d16f6ffDEE38F788B9e22", // 0xd915682d1b33e142f0B1F4822502702e11e2F9e8 old
        BNB : "0x0000000000000000000000000000000000000000",
        // BUSD : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
        USDT : "0x55d398326f99059fF775485246999027B3197955",
        USDC : "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
        DAI:"0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
        AIRDROP_ADDRESS :"0x4753C566678570F2B9e216592bDeCCA43f23eb15", //"0xF2CD85Bba915Ff7F37B2c343e96809fE314E8b80", //"0x385b0e7d746ed4711ed3e7bea48e2d9b5052373a",
        TRANSACTION : "https://bscscan.com/tx/",
        TokenTracker : "https://bscscan.com/token/",
        Testnet : "https://bscscan.com/address/",  //have to change while move to mainet
        MULTISEND_ADDRESS : "0xB1f54D34d9e3BDB30ed57432AF4c214F12D2B924"
    },
    
    // {
    //     NAME: "BSC-TEST",
    //     RPC_URL:"https://sparkling-crimson-pool.bsc-testnet.discover.quiknode.pro/df18f1ec10499d3fcc64dab45f73ed79f2a2d617/",
    //     CHAIN_ID:97,
    //     IMAGE: bsctestnet,
    //     ROUTER: "0x29035dEbC68181B33183E3A7fb9291bC2815644F", //pancake
    //     // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
    //     LIQUIDITY_TOKEN_ADDRESS : "0xf361377AcE5aB0828e7e42afE528be2D5823d730",
    //     STANDARD_TOKEN_ADDRESS : "0x0fA57fA6489926C2Ad02f543657D3A195fbC8DaC",
    //     LOCK_ADDRESS : "0xfDfeC215C4b83EB8b66A4f3387F132F16996F20e", //0x643C924f71E413cC031Cca4C2f60f602A1310792", //0xa44a318F00322FeF490d16f6ffDEE38F788B9e22", // 0xd915682d1b33e142f0B1F4822502702e11e2F9e8 old
    //     BNB : "0x0000000000000000000000000000000000000000",
    //     // BUSD : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
    //     USDT :"0x0A1F4c4E14Dd83018942c5813768A8bf70b78599",// "0xDFCd0b91AE2BB34a90D9b4970Dc113DFaf25004d",//"0x8d9f46183A23d81dcCc172C92bDb4553c1b67FFf",
    //     USDC : "0x0A1F4c4E14Dd83018942c5813768A8bf70b78599",
    //     DAI:"0xeDDC82488ecf00aC8CD8aD942AB3632E41424820",
    //     AIRDROP_ADDRESS :"0x013d2e087EaA45A46DeB2aEA921c33Fc038E7Aa1", //"0xF2CD85Bba915Ff7F37B2c343e96809fE314E8b80", //"0x385b0e7d746ed4711ed3e7bea48e2d9b5052373a",
    //     TRANSACTION : "https://testnet.snowtrace.io/tx/",
    //     TokenTracker : "https://testnet.snowtrace.io/token/",
    //     Testnet : "https://testnet.snowtrace.io/address/",  //have to change while move to mainet
    //     MULTISEND_ADDRESS : "0x3e56E4EcCF989865C9D91701E03FEE1674fF463C"
    // },

    // {
    //     NAME: "Avalanche-TEST",
    //     RPC_URL:"https://api.avax-test.network/ext/bc/C/rpc",
    //     CHAIN_ID:43113,
    //     IMAGE: bsctestnet,
    //     ROUTER: "0x29035dEbC68181B33183E3A7fb9291bC2815644F", //pancake
    //     // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
    //     LIQUIDITY_TOKEN_ADDRESS : "0x06Fb02c073Cda5B84D323C407437E05DA6388ca1",
    //     STANDARD_TOKEN_ADDRESS : "0x3cB251c826bD7b5f3617A0CEE364d8602B4EE58F",
    //     LOCK_ADDRESS : "0x783edf2e8673f2e886a942fdF7629634207Ac30D", //0x643C924f71E413cC031Cca4C2f60f602A1310792", //0xa44a318F00322FeF490d16f6ffDEE38F788B9e22", // 0xd915682d1b33e142f0B1F4822502702e11e2F9e8 old
    //     BNB : "0x0000000000000000000000000000000000000000",
    //     // BUSD : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
    //     USDT : "0x8d9f46183A23d81dcCc172C92bDb4553c1b67FFf",
    //     USDC : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
    //     DAI:"0x8d9f46183A23d81dcCc172C92bDb4553c1b67FFf",
    //     AIRDROP_ADDRESS :"0x3b3bCCa9BC1C5d5f22F5110613062f48530C60a7", //"0xF2CD85Bba915Ff7F37B2c343e96809fE314E8b80", //"0x385b0e7d746ed4711ed3e7bea48e2d9b5052373a",
    //     TRANSACTION : "https://testnet.snowtrace.io/tx/",
    //     TokenTracker : "https://testnet.snowtrace.io/token/",
    //     Testnet : "https://testnet.snowtrace.io/address/",  //have to change while move to mainet
    //     MULTISEND_ADDRESS : "0xA556aCf2A066E465b6330f4d1A2bCfa5d11c1879"
    // },
   

        //Testnet
    

  


    // {
    //     NAME: "Avalanche-TEST",
    //     RPC_URL:"https://api.avax-test.network/ext/bc/C/rpc",
    //     CHAIN_ID:43113,
    //     IMAGE: bsctestnet,
    //     ROUTER: "0x29035dEbC68181B33183E3A7fb9291bC2815644F", //pancake
    //     // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
    //     LIQUIDITY_TOKEN_ADDRESS : "0x36ff68d44e5A38Ac561900431eDd799781DE2c3f",
    //     STANDARD_TOKEN_ADDRESS : "0xF037798BDdEd40336498d3121dC5392a2E676778",
    //     LOCK_ADDRESS : "0x0D42554AEcff5CB84E16ec6F82f3197B5fF3c2ce", // 0xd915682d1b33e142f0B1F4822502702e11e2F9e8 old
    //     BNB : "0x0000000000000000000000000000000000000000",
    //     BUSD : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
    //     USDT : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
    //     USDC : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
    //     AIRDROP_ADDRESS : "0x3b3bCCa9BC1C5d5f22F5110613062f48530C60a7", //"0x385b0e7d746ed4711ed3e7bea48e2d9b5052373a",
    //     TRANSACTION : "https://testnet.snowtrace.io/tx/",
    //     TokenTracker : "https://testnet.snowtrace.io/token/"
    // },
    // {
    //     NAME: "Arbitrum",
    //     RPC_URL:"https://arb1.arbitrum.io/rpc",
    //     CHAIN_ID:42161,
    //     IMAGE: bsctestnet,
    //     ROUTER: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506", //pancake
    //     // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
    //     LIQUIDITY_TOKEN_ADDRESS : "0xf997125D111066E1989c94f18bA097C20ca858Ff",
    //     STANDARD_TOKEN_ADDRESS : "0xc228c9AB8889901C7C6936C6C6B8d5547006d727",
    //     LOCK_ADDRESS : "0x500d3cB1a8709e06C6274CbFa952E4F2a13dd87d", // 0xd915682d1b33e142f0B1F4822502702e11e2F9e8 old
    //     // BNB : "0x0000000000000000000000000000000000000000",
    //     BNB : "0x0000000000000000000000000000000000000000",
    //     DAI  : "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
    //     USDT : "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
    //     USDC : "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
    //     TRANSACTION : "https://arbiscan.io/tx/",
    //     TokenTracker : "https://arbiscan.io/token/" 
    // },
    // {
    //     NAME: "BSC-Testnet",
    //     RPC_URL:"https://sparkling-crimson-pool.bsc-testnet.discover.quiknode.pro/df18f1ec10499d3fcc64dab45f73ed79f2a2d617/",
    //     CHAIN_ID:97,
    //     IMAGE: bsctestnet,
    //     ROUTER: "0x29035dEbC68181B33183E3A7fb9291bC2815644F", //pancake
    //     // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
    //     LIQUIDITY_TOKEN_ADDRESS : "0x527b203C7E0A6463E16DdE46bB71Ce6a9B093595",
    //     STANDARD_TOKEN_ADDRESS : "0x06AAA4C8Da86Ad14c735Faa6B65C009f5B5FfE19",
    //     LOCK_ADDRESS : "0x388Ba9C5A1213aa8D12C9874A49014434C4aB24C", // 0xd915682d1b33e142f0B1F4822502702e11e2F9e8 old
    //     MULTISEND_ADDRESS : "0xf7F2e03D84D5D6882EDd33706197d4B1d5a42Cad",
    //     BNB : "0x0000000000000000000000000000000000000000",
    //     BUSD : "0xbCf448aa88d80C1B7bb8Fb5A1e68cd4B6c11c354",
    //     USDT : "0xDFCd0b91AE2BB34a90D9b4970Dc113DFaf25004d",
    //     USDC : "0xDFCd0b91AE2BB34a90D9b4970Dc113DFaf25004d",
    //     AIRDROP_ADDRESS : "0x70b1f342b950e1d6CE3a1C058817Bef2682927cd", //"0x385b0e7d746ed4711ed3e7bea48e2d9b5052373a",
    //     TRANSACTION : "https://testnet.bscscan.com/tx/",
    //     TokenTracker : "https://testnet.bscscan.com/token/"
    // },
];

//local
// export const API_URL = "http://localhost:7097"


//demo
// export const API_URL = "https://backend-ruby-ido.maticz.in/"

//live
export const API_URL = "https://api.kittyswap.org/"



export const FUND_RECEIVER = "0x12ABeD5514FD6F6716F1154a1Be05beD9E1a11dF"

export const COINS = ["BNB" , "DAI" , "USDT" , "USDC" , "BNB" , "MATIC" , "AVAX" , "CRO" , "FTM"];

export const HC = ["Presale" , "Fairlaunch" , "Auction" , "Subscription"];

export const STATUS = ["Upcoming" , "Live" , "Ended"];

export const TOKEN_FEE = 0.01;

export const ZEROTH_ADDRESS = "0x0000000000000000000000000000000000000000"


// export const WBNB_Address = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"; // BSC Mainet

// export const WBNB_Address = "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd";  //BSC Testnet

export const WBNB_Address = "0x1d308089a2d1ced3f1ce36b1fcaf815b07217be3"

export const PROJECT_ID = "b8a1daa2dd22335a2fe1d2e139980ae0"



// import bsc from "../images/bsc.png"
// import polygon from "../images/polygon.png"
// import bsctestnet from "../images/bsctestnet.png"
// import eth from "../images/eth.png"
// import ropsten from "../images/ropsten.png"
// // export const RPC_URL = "https://bsc-dataseed1.binance.org/";
// // export const CHAIN_ID = 56;
  
// export const RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545/";
//  export const CHAIN_ID = 97;
 
//  export const ONEDAYINSECONDS = 86400;

//  export const userFee = 5

//  export const userFeeWithtoken = 4

//  export const CHAINS = [
//     {
//         NAME: "Avalanche-TEST",
//         RPC_URL:"https://api.avax-test.network/ext/bc/C/rpc",
//         CHAIN_ID:43113,
//         IMAGE: bsctestnet,
//         ROUTER: "0x29035dEbC68181B33183E3A7fb9291bC2815644F", //pancake
//         // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
//         LIQUIDITY_TOKEN_ADDRESS : "0x36ff68d44e5A38Ac561900431eDd799781DE2c3f",
//         STANDARD_TOKEN_ADDRESS : "0xF037798BDdEd40336498d3121dC5392a2E676778",
//         LOCK_ADDRESS : "0x0D42554AEcff5CB84E16ec6F82f3197B5fF3c2ce", // 0xd915682d1b33e142f0B1F4822502702e11e2F9e8 old
//         BNB : "0x0000000000000000000000000000000000000000",
//         BUSD : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
//         USDT : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
//         USDC : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
//         AIRDROP_ADDRESS : "0x3b3bCCa9BC1C5d5f22F5110613062f48530C60a7", //"0x385b0e7d746ed4711ed3e7bea48e2d9b5052373a",
//         TRANSACTION : "https://testnet.snowtrace.io/tx/",
//         TokenTracker : "https://testnet.snowtrace.io/token/"
//     },
//     {
//         NAME: "Arbitrum",
//         RPC_URL:"https://arb1.arbitrum.io/rpc",
//         CHAIN_ID:42161,
//         IMAGE: bsctestnet,
//         ROUTER: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506", //pancake
//         // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
//         LIQUIDITY_TOKEN_ADDRESS : "0xf997125D111066E1989c94f18bA097C20ca858Ff",
//         STANDARD_TOKEN_ADDRESS : "0xc228c9AB8889901C7C6936C6C6B8d5547006d727",
//         LOCK_ADDRESS : "0x500d3cB1a8709e06C6274CbFa952E4F2a13dd87d", // 0xd915682d1b33e142f0B1F4822502702e11e2F9e8 old
//         // BNB : "0x0000000000000000000000000000000000000000",
//         BNB : "0x0000000000000000000000000000000000000000",
//         DAI  : "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
//         USDT : "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
//         USDC : "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
//         TRANSACTION : "https://arbiscan.io/tx/",
//         TokenTracker : "https://arbiscan.io/token/" 
//     },
//     {
//         NAME: "BSC-Testnet",
//         RPC_URL:"https://sparkling-crimson-pool.bsc-testnet.discover.quiknode.pro/df18f1ec10499d3fcc64dab45f73ed79f2a2d617/",
//         CHAIN_ID:97,
//         IMAGE: bsctestnet,
//         ROUTER: "0x29035dEbC68181B33183E3A7fb9291bC2815644F", //pancake
//         // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
//         LIQUIDITY_TOKEN_ADDRESS : "0x527b203C7E0A6463E16DdE46bB71Ce6a9B093595",
//         STANDARD_TOKEN_ADDRESS : "0x06AAA4C8Da86Ad14c735Faa6B65C009f5B5FfE19",
//         LOCK_ADDRESS : "0x388Ba9C5A1213aa8D12C9874A49014434C4aB24C", // 0xd915682d1b33e142f0B1F4822502702e11e2F9e8 old
//         BNB : "0x0000000000000000000000000000000000000000",
//         BUSD : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
//         USDT : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
//         USDC : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
//         AIRDROP_ADDRESS : "0x70b1f342b950e1d6CE3a1C058817Bef2682927cd", //"0x385b0e7d746ed4711ed3e7bea48e2d9b5052373a",
//         TRANSACTION : "https://testnet.snowtrace.io/tx/",
//         TokenTracker : "https://testnet.snowtrace.io/token/"
//     },
// ];

// // export const API_URL = "http://localhost:7097"

// export const API_URL = "http://43.204.99.101:7097"

// // export const API_URL = "https://api.spaceai.finance"

// export const FUND_RECEIVER = "0x12ABeD5514FD6F6716F1154a1Be05beD9E1a11dF"

// export const COINS = ["BNB" , "DAI" , "USDT" , "USDC" , "BNB" , "MATIC" , "AVAX" , "CRO" , "FTM"];

// export const HC = ["Presale" , "Fairlaunch" , "Auction" , "Subscription"];

// export const STATUS = ["Upcoming" , "Live" , "Ended"];

// export const TOKEN_FEE = 0.01;

// export const ZEROTH_ADDRESS = "0x0000000000000000000000000000000000000000"


// // export const WBNB_Address = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"; // BSC Mainet

// // export const WBNB_Address = "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd";  //BSC Testnet

// export const WBNB_Address = "0x1d308089a2d1ced3f1ce36b1fcaf815b07217be3"

// export const PROJECT_ID = "b8a1daa2dd22335a2fe1d2e139980ae0"
