import isEmpty from 'is-empty';


export const ObjectIsempty =(Obj)=>{
    try{
        let objectArr = Object.values(Obj)
        for(let i=0;i<objectArr.length;i++){
            if(!isEmpty(objectArr[i])){
                return false
            }else{
                return true
            }
        }
        return true
    }catch(err){
        console.log(err,'ObjectIsempty_err')
    }
}