import React, { Component } from "react";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import Header from "../Header";
import Sidebar from "../Sidebar";
import Cookies from 'universal-cookie';

import "../../css/styles.css";

import logo_grad from "../../images/Bitdeal/logos.png";


import {
  Container,
  Row,
  Col,
  Card,
  ProgressBar,
  InputGroup,
  FormControl,
  Tab,
  Nav
} from "react-bootstrap";
import { validatepassword } from "../../hooks/kycvalidation";
import { resetPassword } from "../../hooks/usebackend";
import toast from "react-hot-toast";
const cookies = new Cookies();
class Resetpassword extends Component {


  constructor(props) {
    super(props);
    this.state = {
      passwordeye: false,
      confirmpasswordeye: false,
      password:'',
      confirmPass:''
    }
  }


  componentDidMount(){
  console.log("path",this.props.location.pathname.split('/')[2])
  }


  handleSubmit = async()=>{
    let payload = {
      password : this.state.password,
      confirmPass: this.state.confirmPass
    }
    let validate = await validatepassword(payload);

    let data = {
      password : this.state.password,
      authToken: this.props.location.pathname.split('/')[2] 
    }

    if(validate.isValid){
      var resetPass = await resetPassword(data);
      console.log("resetPass",resetPass)
      if(resetPass?.data?.data?.success == "false" || !resetPass?.data?.data?.success){
        toast.error("Admin not found")
      }else {
        toast.success(resetPass?.data?.data?.message)
        console.log("resetPass_resetPass" , resetPass);
        this.props.history.push("/login")

      }
     
    }
    else{
      this.setState({"errors" : validate?.errors})
    }
  }

  render() {


    return (
      <div id="loader_main">

        <div className="logo_overlay" id="logo_overlay" style={{ opacity: 1 }}>
          {/* <Header /> */}

          <div className="whole_sec login_screen pb-5">
            <div>

              <Container className="container">
                <div className="coming_soon login_text">
                  <div className='row'>
                    <div className='col-10 col-sm-8 col-md-8 col-lg-9 mx-auto'>
                      <div className="text-center">
                        <img src={logo_grad} className="img-fluid logo_grad_width" />
                      </div>
                      <div className='card_bg card mt-5'>
                        <div className='card-body'>
                          <div className='row'>

                            <div className='col-12 col-md-12 mb-3'>


                              <p class="text-white">Reset Password</p>
                              <hr class="hr_green mb-0"></hr>

                            </div>


                            <div className='col-12 col-md-12 mt-3 mb-0'>

                              <p className='input_desc_sm'>New Password</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <InputGroup.Text className="left_icon"><span className='fa fa-key'></span></InputGroup.Text>
                                  <FormControl id="password" placeholder="Enter new password"
                                    type={!this.state.passwordeye ? "password" : "text"}
                                    className="center_icon"
                                    aria-describedby="basic-addon2"
                                    onChange={(e) => {
                                      this.setState({ "password": e?.target?.value })
                                    }}

                                  />
                                  {!this.state.passwordeye ?
                                    <InputGroup.Text className="right_icon"><span className='fa fa-eye-slash' onClick={() => { this.setState({ passwordeye: !this.state.passwordeye }) }}></span></InputGroup.Text>
                                    : <InputGroup.Text className="right_icon"><span className='fa fa-eye' onClick={() => { this.setState({ passwordeye: !this.state.passwordeye }) }}></span></InputGroup.Text>
                                  }
                                </InputGroup>
                                <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.password}</span>
                              </div>

                              <p className='input_desc_sm mt-3'>Confirm New Password</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <InputGroup.Text className="left_icon"><span className='fa fa-key'></span></InputGroup.Text>
                                  <FormControl id="password" placeholder="Confirm  new password"
                                    type={!this.state.confirmpasswordeye ? "password" : "text"}
                                    className="center_icon"
                                    aria-describedby="basic-addon2"
                                    onChange={(e) => {
                                      this.setState({ "confirmPass": e?.target?.value })
                                    }}
                                  />
                                  {!this.state.confirmpasswordeye ?
                                    <InputGroup.Text className="right_icon"><span className='fa fa-eye-slash' onClick={() => { this.setState({ confirmpasswordeye: !this.state.confirmpasswordeye }) }}></span></InputGroup.Text>
                                    : <InputGroup.Text className="right_icon"><span className='fa fa-eye' onClick={() => { this.setState({ confirmpasswordeye: !this.state.confirmpasswordeye }) }}></span></InputGroup.Text>
                                  }
                                </InputGroup>
                                <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.confirmPass}</span>
                              </div>
                            </div>


                            <div className='col-12 col-md-12 mt-3 mb-0'>
                              <div className="text-right">
                                <Link to="/login" className="link_ui_admin">Back to Login?</Link>
                              </div>
                            </div>

                            <center className='mt-4 mx-auto'>
                              <button className="get-started-btn mr-3" onClick={()=>{this.handleSubmit()}}>Submit</button>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    );
  }
}

export default Resetpassword;
